<template>
     <div>
          <div
               class="space-y-4 font-roboto text-sm xs:text-base sm:text-lg md:text-xl lg:text-xl xl:text-2xl"
          >
               <div class="w-full h-full">
                    <div class="divide-y divide-shade-12 w-full">
                         <div class="flex items-center pb-2">
                              <div
                                   class="flex-1 flex flex-col items-start gap-1 mb-2"
                              >
                                   <span
                                        class="font-bold text-moozi-1 leading-tight text-base lg:text-lg font-roboto"
                                   >
                                        Email Subscription
                                   </span>
                                   <span
                                        class="text-shade-7 font-normal text-xs lg:text-sm"
                                   >
                                        Subscribe to receive our latest special
                                        coin packages, offers and promotional
                                        events.
                                   </span>
                              </div>
                              <div>
                                   <label
                                        class="flex cursor-pointer select-none items-center"
                                   >
                                        <div class="relative">
                                             <input
                                                  type="checkbox"
                                                  class="sr-only"
                                                  :disabled="responsibleLoading"
                                                  @change="
                                                       handleCheckboxChange(
                                                            'subs'
                                                       )
                                                  "
                                             />
                                             <div
                                                  class="block h-7 w-14 rounded-full shadow-inner"
                                                  :class="
                                                       isCheckedSubscription
                                                            ? 'bg-background-3'
                                                            : 'bg-background-5'
                                                  "
                                             ></div>
                                             <div
                                                  :class="{
                                                       'translate-x-full':
                                                            isCheckedSubscription
                                                  }"
                                                  class="shadow-lg dot absolute left-c top-xc h-4.5 w-4.5 rounded-full bg-white transform ease-in-out transition-all duration-300"
                                             ></div>
                                        </div>
                                   </label>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { saveSubs as saveSubsAPI } from "api/subscription.js";
import { useToaster } from "composables/use-toaster.js";
import { authStore } from "store/auth.js";
import { subscriptionStore } from "store/subscription.js";

export default {
     props: ["social_linked", "setSocialLink"],
     setup() {
          const useAuthStore = authStore();
          const useSubscriptionStore = subscriptionStore();

          const responsibleLoading = ref(false);

          const isCheckedSubscription = ref();

          const subs = computed(() => {
               return useSubscriptionStore.subs;
          });

          const { successToast, errorToast } = useToaster();

          const handleCheckboxChange = async (is_from) => {
               if (is_from == "subs") {
                    responsibleLoading.value = true;
                    isCheckedSubscription.value = !isCheckedSubscription.value;

                    await saveSubsAPI({
                         action: isCheckedSubscription.value
                              ? "subscribe"
                              : "unsubscribe"
                    })
                         .then(() => {
                              successToast(
                                   "Successfully Updated!",
                                   "top-right"
                              );
                              useSubscriptionStore.getSubscription();
                              responsibleLoading.value = false;
                         })
                         .catch(() => {
                              errorToast(
                                   "Error to update reminder!",
                                   "top-right"
                              );
                              responsibleLoading.value = false;
                         });
               }
          };

          onMounted(() => {
               responsibleLoading.value = true;
               useSubscriptionStore.getSubscription();
               isCheckedSubscription.value = subs.value;
               responsibleLoading.value = false;
          });

          onUnmounted(async () => {
               await useAuthStore.getUser(true);
          });

          watch(subs, (newVal) => {
               isCheckedSubscription.value = newVal;
          });

          return {
               handleCheckboxChange,
               isCheckedSubscription,
               responsibleLoading,
               subs
          };
     }
};
</script>

<template>
     <InertiaHead title="Redeem"></InertiaHead>
     <div class="moozi-min-h w-full h-full relative flex justify-center">
          <div
               v-if="isSelfExclusion == null"
               class="w-full 2xl:max-w-screen-2xl bg-transparent relative"
          >
               <div
                    class="w-full h-full flex flex-col justify-center items-center pb-20 lg:pb-26 pt-20"
               >
                    <h1
                         class="lg:hidden text-white font-helvetica tracking-wide text-center text-5xl xs:text-5xl sm:text-5xl md:text-6xl xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl"
                    >
                         REDEEM
                    </h1>
                    <div
                         class="bg-moozi-2 h-full rounded-xl flex flex-col flex-shrink xl:flex-row w-full relative mt-5 lg:mt-0"
                    >
                         <div
                              class="w-full xl:w-4/12 2xl:w-3/12 absolute z-50 lg:static xl:z-0"
                         >
                              <div
                                   class="flex flex-col justify-between items-center w-full h-full"
                              >
                                   <div
                                        class="w-full flex flex-col h-full items-center justify-start xl:pl-7 2xl:pl-10 md:pt-0 xl:pt-5 relative"
                                   >
                                        <div
                                             @click="openMenu = !openMenu"
                                             class="h-12 flex items-center justify-center w-full xl:hidden cursor-pointer"
                                        >
                                             <div
                                                  class="w-4 ml-3 xl:hidden"
                                             ></div>
                                             <span
                                                  class="w-full text-sm lg:text-base capitalize text-white font-roboto tracking-wider !mt-0 font-bold text-center"
                                             >
                                                  {{
                                                       activeSidebarTab.replaceAll(
                                                            "-",
                                                            " "
                                                       )
                                                  }}
                                             </span>
                                             <svg
                                                  :class="
                                                       openMenu
                                                            ? 'rotate-180'
                                                            : 'rotate-0'
                                                  "
                                                  class="transition-transform duration-300 mr-3"
                                                  width="20"
                                                  height="20"
                                                  viewBox="0 0 20 20"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                             >
                                                  <path
                                                       fill-rule="evenodd"
                                                       clip-rule="evenodd"
                                                       d="M5.29279 7.29259C5.48031 7.10512 5.73462 6.99981 5.99979 6.99981C6.26495 6.99981 6.51926 7.10512 6.70679 7.29259L9.99979 10.5856L13.2928 7.29259C13.385 7.19708 13.4954 7.1209 13.6174 7.06849C13.7394 7.01608 13.8706 6.9885 14.0034 6.98734C14.1362 6.98619 14.2678 7.01149 14.3907 7.06177C14.5136 7.11205 14.6253 7.18631 14.7192 7.2802C14.8131 7.37409 14.8873 7.48574 14.9376 7.60864C14.9879 7.73154 15.0132 7.86321 15.012 7.99599C15.0109 8.12877 14.9833 8.25999 14.9309 8.382C14.8785 8.504 14.8023 8.61435 14.7068 8.70659L10.7068 12.7066C10.5193 12.8941 10.265 12.9994 9.99979 12.9994C9.73462 12.9994 9.48031 12.8941 9.29279 12.7066L5.29279 8.70659C5.10532 8.51907 5 8.26476 5 7.99959C5 7.73443 5.10532 7.48012 5.29279 7.29259Z"
                                                       fill="white"
                                                  />
                                             </svg>
                                        </div>
                                        <ul
                                             :class="
                                                  openMenu
                                                       ? 'absolute'
                                                       : 'hidden xl:block'
                                             "
                                             class="overflow-x-auto xl:overflow-x-visible mt-14 md:mt-15 lg:mt-14 xl:mt-0 w-full px-2 xl:px-0"
                                        >
                                             <li
                                                  v-for="(
                                                       items, key
                                                  ) in sidebarTabs"
                                                  :key="key"
                                                  :class="{
                                                       'bg-moozi-2 xl:transparent':
                                                            openMenu,
                                                       'rounded-t-md': key == 0,
                                                       'rounded-b-md':
                                                            key + 1 ==
                                                            sidebarTabs.length
                                                  }"
                                                  class="font-roboto tracking-wider w-full !mt-0 font-bold text-sm sm:text-base"
                                             >
                                                  <span
                                                       @click="
                                                            setSidebarTabs(
                                                                 items.type
                                                            )
                                                       "
                                                       :class="{
                                                            'flex text-secondary-2':
                                                                 activeSidebarTab ==
                                                                      items.type &&
                                                                 openMenu,
                                                            'text-white block':
                                                                 activeSidebarTab !=
                                                                      items.type &&
                                                                 openMenu,
                                                            'text-secondary-2 md:flex':
                                                                 activeSidebarTab ==
                                                                      items.type &&
                                                                 !openMenu,
                                                            'text-white hidden xl:flex':
                                                                 activeSidebarTab !=
                                                                      items.type &&
                                                                 !openMenu
                                                       }"
                                                       class="account-details-list flex items-center px-2 py-3 xl:py-3 rounded-lg w-full cursor-pointer justify-between xl:justify-start"
                                                       aria-current="page"
                                                  >
                                                       <span
                                                            v-if="
                                                                 items.type ==
                                                                 'redeem'
                                                            "
                                                            :class="{
                                                                 active:
                                                                      items.type ==
                                                                      activeSidebarTab
                                                            }"
                                                            class="icon-redeem text-2xl me-3 xl:me-5 hidden xl:block"
                                                       ></span>
                                                       <span
                                                            v-if="
                                                                 items.type ==
                                                                 'redeem-history'
                                                            "
                                                            :class="{
                                                                 active:
                                                                      items.type ==
                                                                      activeSidebarTab
                                                            }"
                                                            class="icon-redeem-history text-xl me-3 xl:me-5 hidden xl:block"
                                                       ></span>
                                                       <div
                                                            class="w-4 ml-3 xl:hidden"
                                                       ></div>
                                                       <span
                                                            class="text-sm lg:text-base"
                                                       >
                                                            {{ items.label }}
                                                       </span>
                                                       <div class="w-7"></div>
                                                  </span>
                                             </li>
                                        </ul>
                                   </div>
                                   <img
                                        :src="
                                             baseUrl.img +
                                             '/assets/img/cow-with-logo.png'
                                        "
                                        alt="cow"
                                        class="mt-8 md:mt-16 mb-8 hidden xl:block"
                                   />
                              </div>
                         </div>
                         <RedeemHistory
                              v-if="activeSidebarTab == 'redeem-history'"
                              :transaction_data="transaction_data"
                              :transaction_data_loading="
                                   transaction_data_loading
                              "
                              :goToTransaction="goToTransaction"
                         />
                         <div
                              v-if="activeSidebarTab == 'redeem'"
                              class="mt-12 md:mt-13 lg:mt-0 h-full bg-white xl:w-8/12 2xl:w-9/12 px-5 md:px-8 xl:px-10 py-5 md:py-8 xl:py-10 flex flex-col justify-between rounded-xl rounded-t-none xl:rounded-l-none xl:rounded-r-xl"
                         >
                              <div class="flex flex-col">
                                   <p
                                        class="text-shade-11 font-helveticaLight tracking-wide mb-4 md:mb-8 text-start font-normal text-sm sm:text-lg md:text-xl lg:text-sm xl:text-base"
                                   >
                                        SCs Coins can only be redeemed for cash
                                        after you have used them to play and is
                                        subject to a minimum of 100 played SCs
                                        Coins.
                                   </p>
                                   <div
                                        class="flex flex-row justify-around items-center w-full mb-8 md:mb-14"
                                   >
                                        <div
                                             class="flex flex-col justify-center items-center"
                                        >
                                             <p
                                                  class="text-shade-11 font-helveticaLight tracking-wide text-start lg:text-center md:text-start font-normal text-sm sm:text-lg md:text-xl lg:text-sm xl:text-base"
                                             >
                                                  Coins balance
                                             </p>
                                             <div
                                                  class="flex flex-row items-center gap-2 mt-4"
                                             >
                                                  <img
                                                       class="cursor-pointer w-6 h-6"
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/SC.svg'
                                                       "
                                                       alt=""
                                                  />
                                                  <p
                                                       class="text-black font-helveticaLight font-bold tracking-wide text-center md: text-sm sm:text-lg md:text-xl lg:text-lg xl:text-lg"
                                                  >
                                                       {{
                                                            money(
                                                                 silver_balance
                                                            )
                                                       }}
                                                       SC
                                                  </p>
                                             </div>
                                        </div>
                                        <div
                                             class="flex flex-col justify-center items-center"
                                        >
                                             <p
                                                  class="text-shade-11 font-helveticaLight tracking-wide text-start lg:text-center md:text-start font-normal text-sm sm:text-lg md:text-xl lg:text-sm xl:text-base"
                                             >
                                                  Available to redeem
                                             </p>
                                             <div
                                                  class="flex flex-row items-center gap-2 mt-4"
                                             >
                                                  <img
                                                       class="cursor-pointer w-6 h-6"
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/SC.svg'
                                                       "
                                                       alt=""
                                                  />
                                                  <p
                                                       class="text-black font-helveticaLight font-bold tracking-wide text-center md: text-sm sm:text-lg md:text-xl lg:text-lg xl:text-lg"
                                                  >
                                                       {{
                                                            money(
                                                                 silver_balance_redeem
                                                            )
                                                       }}
                                                       SC
                                                  </p>
                                             </div>
                                        </div>
                                   </div>
                                   <div class="flex justify-start w-full">
                                        <div class="flex flex-row gap-2">
                                             <div
                                                  v-if="
                                                       isActionAccessible(
                                                            'wd_card_button'
                                                       )
                                                  "
                                                  @click="tabs = 'credit'"
                                                  class="payment-method-tab flex flex-row items-center gap-2 hover:bg-background-3 hover:text-white font-bold font-roboto text-sm lg:text-base tracking-wide rounded-full px-6 py-2 cursor-pointer transition-colors duration-300"
                                                  :class="
                                                       tabs == 'credit'
                                                            ? 'bg-background-3 text-white'
                                                            : 'bg-white text-black'
                                                  "
                                             >
                                                  <svg
                                                       width="20"
                                                       height="19"
                                                       viewBox="0 0 20 19"
                                                       fill="none"
                                                       xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                       <path
                                                            d="M3.07292 2.375C1.98128 2.375 1.09375 3.28789 1.09375 4.41071V5.42857H18.9063V4.41071C18.9063 3.28789 18.0187 2.375 16.9271 2.375H3.07292ZM18.9063 8.48214H1.09375V14.5893C1.09375 15.7121 1.98128 16.625 3.07292 16.625H16.9271C18.0187 16.625 18.9063 15.7121 18.9063 14.5893V8.48214ZM4.55729 12.5536H6.53646C6.80859 12.5536 7.03125 12.7826 7.03125 13.0625C7.03125 13.3424 6.80859 13.5714 6.53646 13.5714H4.55729C4.28516 13.5714 4.0625 13.3424 4.0625 13.0625C4.0625 12.7826 4.28516 12.5536 4.55729 12.5536ZM8.02083 13.0625C8.02083 12.7826 8.24349 12.5536 8.51563 12.5536H12.474C12.7461 12.5536 12.9688 12.7826 12.9688 13.0625C12.9688 13.3424 12.7461 13.5714 12.474 13.5714H8.51563C8.24349 13.5714 8.02083 13.3424 8.02083 13.0625Z"
                                                            :fill="
                                                                 tabs ==
                                                                 'credit'
                                                                      ? '#FFF'
                                                                      : '#000'
                                                            "
                                                       />
                                                  </svg>
                                                  <span class="mt-1"
                                                       >Credit / Debit
                                                       Cards</span
                                                  >
                                             </div>
                                             <div
                                                  v-if="
                                                       isActionAccessible(
                                                            'wd_bank_button'
                                                       )
                                                  "
                                                  @click="tabs = 'wallet'"
                                                  class="payment-method-tab flex flex-row gap-2 items-center hover:bg-background-3 hover:text-white font-bold font-roboto text-sm lg:text-base tracking-wide rounded-full px-6 py-2 cursor-pointer transition-colors duration-300"
                                                  :class="
                                                       tabs == 'wallet'
                                                            ? 'bg-background-3 text-white'
                                                            : 'bg-white text-black'
                                                  "
                                             >
                                                  <svg
                                                       width="19"
                                                       height="19"
                                                       viewBox="0 0 19 19"
                                                       fill="none"
                                                       xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                       <g
                                                            clip-path="url(#clip0_4949_27832)"
                                                       >
                                                            <path
                                                                 d="M9.06422 0.682465L1.27125 4.02231C0.784194 4.23105 0.512831 4.7529 0.620681 5.26779C0.72853 5.78268 1.1808 6.15842 1.70961 6.15842V6.43674C1.70961 6.89944 2.08186 7.2717 2.54457 7.2717H16.4606C16.9233 7.2717 17.2955 6.89944 17.2955 6.43674V6.15842C17.8244 6.15842 18.2801 5.78616 18.3845 5.26779C18.4888 4.74942 18.2175 4.22757 17.7339 4.02231L9.94093 0.682465C9.66261 0.564178 9.34254 0.564178 9.06422 0.682465ZM5.04945 8.38498H2.82289V15.2143C2.80202 15.2247 2.78114 15.2386 2.76027 15.2525L1.09035 16.3658C0.683303 16.6372 0.498915 17.1451 0.641555 17.6148C0.784194 18.0844 1.21907 18.4045 1.70961 18.4045H17.2955C17.7861 18.4045 18.2175 18.0844 18.3601 17.6148C18.5028 17.1451 18.3219 16.6372 17.9113 16.3658L16.2414 15.2525C16.2205 15.2386 16.1997 15.2282 16.1788 15.2143L16.1823 8.38498H13.9557V15.0647H12.5641V8.38498H10.3375V15.0647H8.66762V8.38498H6.44105V15.0647H5.04945V8.38498ZM9.50258 2.81857C9.79784 2.81857 10.081 2.93586 10.2898 3.14465C10.4986 3.35343 10.6159 3.63659 10.6159 3.93185C10.6159 4.22711 10.4986 4.51028 10.2898 4.71906C10.081 4.92784 9.79784 5.04514 9.50258 5.04514C9.20732 5.04514 8.92415 4.92784 8.71537 4.71906C8.50659 4.51028 8.3893 4.22711 8.3893 3.93185C8.3893 3.63659 8.50659 3.35343 8.71537 3.14465C8.92415 2.93586 9.20732 2.81857 9.50258 2.81857Z"
                                                                 :fill="
                                                                      tabs ==
                                                                      'wallet'
                                                                           ? '#FFF'
                                                                           : '#000'
                                                                 "
                                                            />
                                                       </g>
                                                       <defs>
                                                            <clipPath
                                                                 id="clip0_4949_27832"
                                                            >
                                                                 <rect
                                                                      width="19"
                                                                      height="19"
                                                                      :fill="
                                                                           tabs ==
                                                                           'wallet'
                                                                                ? '#FFF'
                                                                                : '#000'
                                                                      "
                                                                 />
                                                            </clipPath>
                                                       </defs>
                                                  </svg>
                                                  <span class="mt-1"
                                                       >Bank Transfer</span
                                                  >
                                             </div>
                                        </div>
                                   </div>
                                   <p
                                        class="text-sm xl:text-base mt-5 mb-3 text-moozi-1 font-bold"
                                   >
                                        Current Account
                                   </p>
                                   <CreditCard
                                        v-if="
                                             tabs == 'credit' &&
                                             isActionAccessible(
                                                  'wd_card_button'
                                             )
                                        "
                                        :form="form"
                                        :onUpdateID="
                                             (val, data) => {
                                                  form.accountId = val;
                                                  card_selected = data;
                                             }
                                        "
                                        :clearFormError="clearFormError"
                                        :card_selected="card_selected"
                                        :hideForm="true"
                                        :updateForm="
                                             (key, value) => {
                                                  updateForm(key, value);
                                             }
                                        "
                                   />
                                   <Wallet
                                        v-if="
                                             tabs == 'wallet' &&
                                             isActionAccessible(
                                                  'wd_bank_button'
                                             )
                                        "
                                        :form="form"
                                        :onUpdateID="
                                             (val) => {
                                                  form.accountId = val;
                                             }
                                        "
                                        :setLoading="setLoading"
                                   ></Wallet>
                                   <div
                                        class="border border-solid border-shade-6 my-2 w-full"
                                   ></div>
                                   <p
                                        v-if="tabs == 'credit'"
                                        class="text-shade-8 text-xs md:text-sm lg:text-xs xl:text-sm my-3 text-center"
                                   >
                                        Moozi SC Coins Redemption supports
                                        following card types:
                                   </p>
                                   <div
                                        v-if="tabs == 'credit'"
                                        class="flex flex-row gap-2 items-center justify-center w-full"
                                   >
                                        <p
                                             class="text-shade-8 text-xs md:text-sm lg:text-xs xl:text-sm my-5 font-bold"
                                        >
                                             Debit Card
                                        </p>
                                        <img
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/card/visa-rounded.png'
                                             "
                                             alt="visa"
                                        />

                                        <!-- <img
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/card/master-rounded.png'
                                             "
                                             alt="master"
                                        />
                                        | -->

                                        <p
                                             class="text-shade-8 text-xs md:text-sm lg:text-xs xl:text-sm my-5 font-bold"
                                        >
                                             Credit Card
                                        </p>
                                        <img
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/card/visa-rounded.png'
                                             "
                                             alt="visa"
                                        />
                                   </div>

                                   <!-- <div
                                        v-if="tabs == 'credit'"
                                        class="flex flex-row gap-2 items-center justify-center w-full"
                                   >
                                        <img
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/card/master-rounded.png'
                                             "
                                             alt="master"
                                        />

                                        <p
                                             class="text-shade-8 text-xs md:text-sm lg:text-xs xl:text-sm my-1 text-center"
                                        >
                                             MasterCard is only available
                                             through ApplePay.
                                        </p>
                                   </div> -->

                                   <p
                                        class="text-sm xl:text-base mt-3 text-moozi-1 font-bold"
                                   >
                                        Redeem Amount
                                   </p>
                                   <p
                                        class="text-shade-11 font-helveticaLight tracking-wide text-start w-full font-normal text-xs lg:text-sm"
                                   >
                                        Input the amount you want to redeem.
                                        Minimum 100 SC coins.
                                   </p>
                                   <transition
                                        enter-from-class="opacity-0"
                                        enter-active-class="transition ease-in duration-300"
                                        enter-to-class="opacity-100 scale-100"
                                   >
                                        <div
                                             v-if="checkActionRestricted()"
                                             class="rounded-xl !mt-0 bg-primal-yellow text-shade-19 flex flex-row items-center gap-2 ease-in-out transition-all duration-300 p-3 mb-4 w-full"
                                        >
                                             <svg
                                                  class="w-5 h-5"
                                                  viewBox="0 0 20 20"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                             >
                                                  <path
                                                       d="M10 7V11M10 14V14.01M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C2.80031 4.47177 2.13738 5.46392 1.68508 6.55585C1.23279 7.64778 1 8.8181 1 10Z"
                                                       stroke="#FFF2B1"
                                                       stroke-width="2"
                                                       stroke-linecap="round"
                                                       stroke-linejoin="round"
                                                  />
                                             </svg>
                                             <p
                                                  class="text-sm lg:text-base font-medium"
                                             >
                                                  This action is currently
                                                  restricted pls contact support
                                             </p>
                                        </div>
                                   </transition>
                                   <input
                                        v-model="form.amount"
                                        type="text"
                                        name="amount"
                                        id="amount"
                                        :readonly="
                                             tabs == 'credit'
                                                  ? !isActionAccessible(
                                                         'wd_card_button'
                                                    )
                                                  : !isActionAccessible(
                                                         'wd_bank_button'
                                                    )
                                        "
                                        class="bg-shade-6 border border-shade-12 font-helveticaLight tracking-wide text-lg rounded-lg focus:!ring-background-2 focus:!border-background-2 block w-full p-2.5 focus:bg-blue-10 mt-3"
                                        @change="clearFormError('amount')"
                                        :class="[
                                             form.errors?.errors?.amount != null
                                                  ? '!border-primal-red focus:!ring-primal-red focus:!border-primal-red'
                                                  : '!border-background-3 focus:!ring-background-2 focus:!border-background-2',
                                             form.amount != ''
                                                  ? 'text-shade-2'
                                                  : 'text-shade-13'
                                        ]"
                                   />
                                   <p
                                        class="w-full font-helveticaLight tracking-wide text-sm text-red-10 mt-1"
                                   >
                                        {{ form.errors?.errors?.amount }}
                                   </p>
                              </div>
                              <vue-recaptcha
                                   v-if="initRecaptcha"
                                   :sitekey="VITE_RECAPTCHA_SITE_KEY"
                                   @verify="verifyMethod"
                                   @expired="expiredMethod"
                                   @render="renderMethod"
                                   @error="errorMethod"
                                   class="my-5 w-full flex items-center justify-center"
                              ></vue-recaptcha>
                              <div class="flex w-full">
                                   <div
                                        :class="
                                             tokenResponse != null &&
                                             form.amount != '' &&
                                             form.accountId != '' &&
                                             form.amount >= 100 &&
                                             parseInt(form.amount) <=
                                                  parseInt(
                                                       silver_balance_redeem
                                                  ) &&
                                             !checkActionRestricted()
                                                  ? 'bg-secondary-3 text-primary-1 cursor-pointer'
                                                  : 'bg-gray-190 text-shade-7 cursor-not-allowed'
                                        "
                                        class="rounded-full w-full text-center font-roboto font-bold tracking-wider px-10 py-3 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                                        :style="
                                             tokenResponse != null &&
                                             form.amount != '' &&
                                             form.accountId != '' &&
                                             form.amount >= 100 &&
                                             parseInt(form.amount) <=
                                                  parseInt(
                                                       silver_balance_redeem
                                                  ) &&
                                             !checkActionRestricted()
                                                  ? ''
                                                  : 'box-shadow: 0px 4px 5.7px -2px rgba(0, 0, 0, 0.15) inset;'
                                        "
                                        @click="
                                             () => {
                                                  if (
                                                       tokenResponse != null &&
                                                       form.amount != '' &&
                                                       form.accountId != '' &&
                                                       form.amount >= 100 &&
                                                       parseInt(form.amount) <=
                                                            parseInt(
                                                                 silver_balance_redeem
                                                            ) &&
                                                       !checkActionRestricted()
                                                  ) {
                                                       onSubmit();
                                                  }
                                             }
                                        "
                                   >
                                        Submit
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <div
               v-else-if="isSelfExclusion != null"
               class="min-h-screen -mt-16 flex justify-center items-center"
          >
               <SelfExclusionAlert />
          </div>
          <WithdrawReminder
               :tabs="tabs"
               :onWithdrawCredit="() => withdrawSCCredit()"
               :onWithdrawWallet="() => withdrawSCWallet()"
          />
          <loading-overlay :isLoading="loading" />
          <AddBankPayment />
          <BankWallet />
     </div>
</template>
<script>
import { reactive, ref, onMounted, computed, watch } from "vue";
import { Head as InertiaHead } from "@inertiajs/vue3";
import _ from "underscore";
import { useFormatter } from "composables/use-formatter.js";
import { useRouter } from "vue-router";
import { coinsStore } from "store/coins";
import { authStore } from "store/auth.js";
import Form from "vform";
import {
     make_withdraw as makeWithdrawAPI,
     make_withdraw_cc as makeWithdrawccAPI,
     transaction_history as transactionHistoryAPi,
     limitTransaction as limitTransactionAPI
} from "api/transaction.js";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { useToaster } from "composables/use-toaster.js";
import { useScroll } from "composables/use-scroll.js";
import WithdrawReminder from "components/modals/withdrawReminder.vue";
import eventBus from "plugins/event.js";
import CreditCard from "./component/credit.vue";
import Wallet from "./component/wallet.vue";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";
import { useActionRestriction } from "composables/use-restriction.js";
import { useBank } from "composables/use-bank.js";
import { cardStore } from "store/cards.js";
const VITE_RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;
import { VueRecaptcha } from "vue-recaptcha";
import RedeemHistory from "./component/tabs/redeem-history.vue";
import AddBankPayment from "components/modals/addBankPayment/index.vue";
import BankWallet from "components/modals/bankWallet.vue";
import { eprotectUrl } from "config/services.js";
import SelfExclusionAlert from "components/selfExclusionAlert/index.vue";
export default {
     components: {
          InertiaHead,
          LoadingOverlay,
          WithdrawReminder,
          CreditCard,
          Wallet,
          VueRecaptcha,
          RedeemHistory,
          AddBankPayment,
          BankWallet,
          SelfExclusionAlert
     },
     setup() {
          const { isActionAccessible } = useBank();
          const { isActionRestricted } = useActionRestriction();

          const { date6, money } = useFormatter();
          const router = useRouter();
          const { scrollTop } = useScroll();

          const loading = ref(false);
          const { infoToast, successToast, errorToast } = useToaster();

          const useCoinsStore = coinsStore();
          const useAuthStore = authStore();
          const useUtilitiesStore = utilitiesStore();
          const useCardStore = cardStore();

          const openMenu = ref(false);

          const activeSidebarTab = ref("redeem");

          const sidebarTabs = ref([
               { label: "Redeem", type: "redeem" },
               { label: "Redeem History", type: "redeem-history" }
          ]);

          const transaction_data = ref([]);
          const transaction_data_loading = ref(false);
          const transaction_limit = ref(null);

          const card_selected = ref(null);

          const tokenResponse = ref(null);
          const initRecaptcha = ref(false);

          const tabs = ref("wallet");

          const bankStatus = computed(() => {
               if (useCardStore.bankStatus) {
                    setactiveSidebarTab(useCardStore.bankStatus);
               }
               return useCardStore.bankStatus;
          });

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const isSelfExclusion = computed(() => {
               return useAuthStore.isSelfExclusion;
          });

          const form = reactive(
               new Form({
                    amount: "",
                    accountId: "",
                    type: "wallet",
                    billing: {
                         address_1: "",
                         address_2: "",
                         state: "",
                         city: "",
                         zipCode: ""
                    },
                    card_number: "",
                    card_expiration: ""
               })
          );

          const coins = computed(() => {
               return useCoinsStore.coins || null;
          });

          const silver_balance = computed(() => {
               return (
                    parseFloat(useAuthStore.silver_balance) +
                    parseFloat(useAuthStore.silver_balance_redeem)
               );
          });

          const silver_balance_redeem = computed(() => {
               return parseFloat(useAuthStore.silver_balance_redeem);
          });

          const filterCoins = (val) => {
               return _.filter(coins.value.coins, function (params) {
                    return params["type"] == val;
               });
          };

          const worldpayStatus = computed(() => {
               return useCoinsStore.worldpayStatus == true ? "prod" : "dev";
          });

          const goTo = (id) => {
               router.push({ path: "/payment/" + id });
          };

          const goToTransaction = () => {
               router.push({
                    path: "/account/",
                    query: { tab: "coins", filter: "withdraw" }
               });
          };

          const goToWallet = () => {
               router.push({
                    path: "/account/",
                    query: { tab: "payment-details", panel: "wallet" }
               });
          };

          const onSubmit = async () => {
               if (form.accountId == "") {
                    errorToast("Please Select Wallet!", "top-right");
                    return;
               }

               const temp = tabs.value == "credit" ? "worldpay" : "fiserv";

               const filter = _.filter(
                    transaction_limit.value,
                    function (params) {
                         return params["provider"].toLowerCase() == temp;
                    }
               );

               if (
                    parseInt(form.amount) >=
                    parseInt(filter[0]["wd_max_transaction"])
               ) {
                    openWidthdrawReminder();
               } else {
                    if (tabs.value == "credit") {
                         await withdrawSCCredit();
                    } else {
                         await withdrawSCWallet();
                    }
               }
          };

          const withdrawSCWallet = async (isPending = false) => {
               const params = {};
               params["accountId"] = form.accountId;
               params["amount"] = form.amount;
               params["amount"] = form.amount;
               params["g-recaptcha-response"] = tokenResponse.value;

               loading.value = true;
               await makeWithdrawAPI(params)
                    .then(async (res) => {
                         if (res) {
                              await withdrawTransaction();
                              await useAuthStore.reloadBalance({
                                   type: "silver_coins"
                              });
                              closeWidthdrawReminder();

                              if (isPending) {
                                   infoToast(
                                        "Please wait for a moment! you will receive your money in few moments",
                                        "top-right"
                                   );
                              } else {
                                   successToast(
                                        "Redeem Successful",
                                        "top-right",
                                        "redemptionSuccessPayment"
                                   );
                              }
                              delete form.errors.errors["amount"];
                              form.amount = "";
                              loading.value = false;

                              initRecaptcha.value = false;
                              tokenResponse.value = null;
                              setTimeout(() => {
                                   initRecaptcha.value = true;
                              }, 1000);
                         }
                    })
                    .catch(() => {
                         closeWidthdrawReminder();
                         loading.value = false;
                         initRecaptcha.value = false;
                         tokenResponse.value = null;
                         errorToast("Redeem Unsuccessful", "top-right");
                         setTimeout(() => {
                              initRecaptcha.value = true;
                         }, 1000);
                    });
          };

          const withdrawSCCredit = async (isPending = false) => {
               loading.value = true;

               setTimeout(async () => {
                    // const tempState = form.billing.state;
                    // const filterState = _.filter(state.value, function (params) {
                    //      return params["province_name"] == tempState;
                    // })
                    const params = {
                         amount: form.amount,
                         card_id: form.accountId,
                         "g-recaptcha-response": tokenResponse.value
                         // billing: {
                         //      address_1: form.billing.address_1,
                         //      state: filterState[0]["province_name_abbr"],
                         //      city: form.billing.city,
                         //      zipcode: form.billing.zipCode,
                         // },
                         // card_number: form.card_number,
                         // card_type: card_selected?.value.paypage?.type,
                         // card_expiration: card_selected?.value.paypage?.card_expiration != null ? card_selected?.value.paypage?.card_expiration : form.card_expiration,
                         // payment_category: 'CARD'
                    };
                    // THIS ONLY FOR TESTING

                    await makeWithdrawccAPI(params)
                         .then(async (res) => {
                              if (res) {
                                   await withdrawTransaction();
                                   await useAuthStore.reloadBalance({
                                        type: "silver_coins"
                                   });
                                   closeWidthdrawReminder();

                                   if (isPending) {
                                        infoToast(
                                             "Please wait for a moment! you will receive your money in few moments",
                                             "top-right"
                                        );
                                   } else {
                                        successToast(
                                             "Redeem Successful",
                                             "top-right",
                                             "redemptionSuccessPayment"
                                        );
                                   }

                                   delete form.errors.errors["amount"];
                                   form.amount = "";
                                   loading.value = false;
                                   card_selected.value = null;

                                   initRecaptcha.value = false;
                                   tokenResponse.value = null;
                                   setTimeout(() => {
                                        initRecaptcha.value = true;
                                   }, 1000);
                              }
                         })
                         .catch(() => {
                              closeWidthdrawReminder();
                              loading.value = false;
                              initRecaptcha.value = false;
                              tokenResponse.value = null;
                              errorToast("Redeem Unsuccessful", "top-right");
                              setTimeout(() => {
                                   initRecaptcha.value = true;
                              }, 1000);
                         });
               }, 500);
          };

          const clearFormError = (key) => {
               if (
                    form.amount < 100 ||
                    form.amount > parseInt(silver_balance_redeem.value)
               ) {
                    form.errors.errors[key] = "invalid amount";
               } else {
                    if (typeof form.errors.errors[key] !== "undefined") {
                         delete form.errors.errors[key];
                    }
               }
          };

          const transactionLimit = async () => {
               await limitTransactionAPI()
                    .then((res) => {
                         if (res) {
                              transaction_limit.value = res;
                         }
                    })
                    .catch((err) => {
                         console.error(err);
                    });
          };

          const withdrawTransaction = async () => {
               transaction_data_loading.value = true;
               await transactionHistoryAPi({
                    page: 1,
                    itemsPerPage: 12,
                    transaction_type: "withdraw"
               }).then((data) => {
                    let arr = [];
                    data.data.map((d) => {
                         arr.push("");
                         arr.push(d);
                    });
                    transaction_data.value = arr;
               });
               transaction_data_loading.value = false;
          };

          const openWidthdrawReminder = () => {
               eventBus.emit("open:withdraw_reminder");
          };

          const closeWidthdrawReminder = () => {
               eventBus.emit("close:withdraw_reminder");
          };

          const updateForm = (key, value) => {
               const split = key.split(".");
               if (split.length == 1) {
                    form[key] = value;
               } else if (split.length == 2) {
                    form[split[0]][split[1]] = value;
               }
          };

          const checkActionRestricted = () => {
               // WITHDRAW
               const restrictedActionType = 2;

               return isActionRestricted(restrictedActionType);
          };

          const verifyMethod = async (response) => {
               tokenResponse.value = response;
          };

          const expiredMethod = async () => {
               tokenResponse.value = null;
               console.error("expired");
          };

          const renderMethod = async () => {};

          const errorMethod = async () => {
               tokenResponse.value = null;
               console.error("error");
          };

          const setSidebarTabs = async (tab) => {
               activeSidebarTab.value = tab;

               if (window.outerWidth < 1024) {
                    openMenu.value = !openMenu.value;
               }

               // if (tab == "account-details") {
               //      resetProfileForm();
               // }

               // if (tab == "coins") {
               //      if(fromQueryFilter.value == true) {
               //           filter.value = null;
               //           filterLabel.value = null;
               //           fromQueryFilter.value = false

               //           const params = {
               //                page: 1,
               //                itemsPerPage: 10,
               //           };

               //           params['transaction_type'] = '';

               //           await getTransactionHistory(params);
               //      }
               // }

               // router.replace({ query: { tab: tab } });
          };

          const setLoading = (bool) => {
               loading.value = bool;
          };

          const setactiveSidebarTab = (newVal) => {
               const filter = _.filter(newVal, function (params) {
                    return ["wd_card_button", "wd_bank_button"].includes(
                         params["slug"]
                    );
               });

               if (filter?.length != 0) {
                    const bank = _.filter(newVal, function (params) {
                         return params["slug"] == "wd_bank_button";
                    });

                    const card = _.filter(newVal, function (params) {
                         return params["slug"] == "wd_card_button";
                    });

                    if (card[0].note == 1) {
                         tabs.value = "credit";
                         return;
                    } else if (bank[0].note == 1) {
                         tabs.value = "wallet";
                         return;
                    }
                    return;
               }

               tabs.value = "credit";
          };

          onMounted(async () => {
               transaction_data_loading.value = true;
               scrollTop();
               initRecaptcha.value = true;
               await useCoinsStore.getWorldPay();
               await useCardStore.getBankStatus();
               await useCoinsStore.getCoins();
               await useAuthStore.reloadBalance({ type: "silver_coins" });
               await transactionLimit();
               await withdrawTransaction();
               await useUtilitiesStore.getState();

               const isExist = document.querySelector("#fiserv-import");
               if (isExist == null) {
                    var script = document.createElement("script");
                    script.id = "fiserv-import";
                    script.src =
                         import.meta.env.VITE_ENV == "production"
                              ? "https://prod.api.firstdata.com/gateway/v2/connectpay/static/v1/js/PaymentSDK.js"
                              : "https://cat.api.firstdata.com/gateway/v2/connectpay/static/v1/js/PaymentSDK.js";
                    document.body.appendChild(script);
               }

               const isExist1 = document.querySelector(
                    "#eprotect-jquery-import"
               );
               const isExist2 = document.querySelector("#eprotect-import");

               if (isExist1 == null && isExist2 == null) {
                    var script1 = document.createElement("script");
                    script1.id = "eprotect-jquery-import";
                    script1.src =
                         eprotectUrl[worldpayStatus.value][
                              "eprotect-jquery-import"
                         ];
                    document.body.appendChild(script1);

                    var script2 = document.createElement("script");
                    script2.id = "eprotect-import";
                    // https://request.eprotect.vantivprelive.com/eProtect/js/eProtect-iframe-client4.min.js
                    script2.src =
                         eprotectUrl[worldpayStatus.value]["eprotect-import"];
                    document.body.appendChild(script2);
               }
          });

          watch(tabs, (newVal) => {
               form.type = newVal;
               form.accountId = "";
               card_selected.value = null;
          });

          watch(bankStatus, (newVal) => {
               if (newVal) {
                    setactiveSidebarTab(newVal);
               }
          });

          return {
               coins,
               filterCoins,
               date6,
               money,
               goTo,
               silver_balance,
               silver_balance_redeem,
               form,
               onSubmit,
               transaction_data,
               clearFormError,
               loading,
               goToTransaction,
               goToWallet,
               transaction_data_loading,
               tabs,
               updateForm,
               card_selected,
               baseUrl,
               checkActionRestricted,
               isActionAccessible,
               withdrawSCCredit,
               withdrawSCWallet,
               VITE_RECAPTCHA_SITE_KEY,
               verifyMethod,
               expiredMethod,
               renderMethod,
               errorMethod,
               tokenResponse,
               openMenu,
               setSidebarTabs,
               sidebarTabs,
               activeSidebarTab,
               isUser,
               setLoading,
               initRecaptcha,
               isSelfExclusion
          };
     }
};
</script>

<template>
     <div class="w-full h-full flex flex-col justify-between">
          <div>
               <p
                    class="text-shade-8 text-xs md:text-sm lg:text-xs xl:text-sm my-5"
               >
                    Connect your bank account for easier payment setup with no
                    expiration and faster refunds.
               </p>
               <div v-if="enrolled != null && enrolled?.account?.length">
                    <p
                         class="text-xs md:text-sm lg:text-xs xl:text-sm mb-5 text-black font-bold"
                    >
                         Current account
                    </p>
                    <div
                         v-if="
                              walletCount == 0 &&
                              enrolled?.account[0].accountStatus == '40'
                         "
                         class="h-full flex flex-col justify-between"
                    >
                         <div
                              class="border border-shade-10 py-3 px-4 w-full text-center text-shade-10 text-sm lg:text-base rounded-lg mb-4"
                         >
                              No bank connected. Please link new account.
                         </div>
                    </div>
                    <div
                         v-else
                         class="flex flex-col gap-2"
                    >
                         <div
                              v-for="(item, key) in getActiveAccount(
                                   enrolled?.account
                              )"
                              :key="key"
                              class="w-full"
                         >
                              <div
                                   @click="setSelectedWallet(item)"
                                   class="flex flex-row items-center justify-between w-full gap-2 px-4 py-3 rounded-lg transition-all duration-300 ease-out bg-gray-150 border border-solid cursor-pointer"
                                   :class="
                                        selectedWallet?.fdAccountID ==
                                        item.fdAccountID
                                             ? 'border-background-7'
                                             : 'border-shade-10'
                                   "
                              >
                                   <div class="flex flex-row w-full">
                                        <span
                                             class="w-full flex flex-row gap-2 justify-start items-center text-black text-xs md:text-sm lg:text-xs xl:text-sm"
                                        >
                                             <img
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/card/bank-icon.png'
                                                  "
                                                  class="h-6"
                                             />
                                             <div class="flex flex-col">
                                                  <span
                                                       class="w-full capitalize text-nowrap text-ellipsis overflow-hidden font-bold text-moozi-1"
                                                  >
                                                       {{
                                                            item
                                                                 .userIdentificationDetails
                                                                 .bankName
                                                       }}
                                                  </span>
                                                  <span>
                                                       Account ending in
                                                       <span class="font-bold">
                                                            {{
                                                                 item
                                                                      .userIdentificationDetails
                                                                      .bankAccountActualLastFour
                                                            }}
                                                       </span>
                                                  </span>
                                             </div>
                                        </span>
                                   </div>
                                   <div
                                        class="flex flex-col h-full gap-1 items-end"
                                   >
                                        <span
                                             @click="
                                                  (e) => {
                                                       e.stopPropagation();
                                                       e.preventDefault();
                                                       fetchSDK(
                                                            'edit',
                                                            item.fdAccountID
                                                       );
                                                  }
                                             "
                                             class="cursor-pointer"
                                        >
                                             <span
                                                  class="underline underline-offset-2 cursor-pointer flex flex-col justify-end items-start text-black text-xs lg:text-sm"
                                             >
                                                  Edit
                                             </span>
                                        </span>
                                        <span
                                             @click="
                                                  (e) => {
                                                       e.stopPropagation();
                                                       e.preventDefault();
                                                       fetchSDK(
                                                            'close',
                                                            item.fdAccountID
                                                       );
                                                  }
                                             "
                                             class="cursor-pointer"
                                        >
                                             <span
                                                  class="underline underline-offset-2 cursor-pointer flex flex-col justify-end items-start text-black text-xs lg:text-sm"
                                             >
                                                  Remove
                                             </span>
                                        </span>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <div
                    v-else-if="enrolled == null"
                    class="h-full w-full"
               >
                    <div
                         v-if="loading"
                         class="flex item-center justify-center w-full py-32"
                    >
                         <svg
                              aria-hidden="true"
                              class="w-8 h-8 text-black animate-spin fill-secondary-1"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                         >
                              <path
                                   d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                   fill="currentColor"
                              ></path>
                              <path
                                   d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                   fill="currentFill"
                              ></path>
                         </svg>
                    </div>
               </div>
               <div
                    v-else
                    class="h-full flex flex-col"
               >
                    <p
                         class="text-xs md:text-sm lg:text-xs xl:text-sm mb-5 text-black font-bold"
                    >
                         Current account
                    </p>
                    <div
                         class="border border-shade-10 py-3 px-4 w-full text-center text-shade-10 text-sm lg:text-base rounded-lg mb-4"
                    >
                         No bank connected. Please link new account.
                    </div>
               </div>
          </div>
          <div class="flex flex-col">
               <transition
                    enter-from-class="opacity-0"
                    enter-active-class="transition ease-in duration-300"
                    enter-to-class="opacity-100 scale-100"
               >
                    <div
                         v-if="
                              dailyLimit != null &&
                              limitReachValue + coins.total > dailyLimit
                         "
                         class="rounded-xl !mt-4 bg-primal-yellow text-shade-19 flex flex-row items-center gap-2 ease-in-out transition-all duration-300 p-3 mb-4 w-full"
                    >
                         <svg
                              class="w-5 h-5"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                         >
                              <path
                                   d="M10 7V11M10 14V14.01M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C2.80031 4.47177 2.13738 5.46392 1.68508 6.55585C1.23279 7.64778 1 8.8181 1 10Z"
                                   stroke="#FFF2B1"
                                   stroke-width="2"
                                   stroke-linecap="round"
                                   stroke-linejoin="round"
                              />
                         </svg>
                         <p class="text-sm lg:text-base font-medium">
                              {{
                                   limitReachValue >= dailyLimit
                                        ? "You have already exceeded your set purchase limit. "
                                        : "This purchasewill exceed your purchase limit. "
                              }}
                              <span
                                   class="underline underline-offset-2 cursor-pointer"
                                   @click="gotoResponsiblePlay"
                                   >Go to Responsible Play</span
                              >
                         </p>
                    </div>
               </transition>
               <transition
                    enter-from-class="opacity-0"
                    enter-active-class="transition ease-in duration-300"
                    enter-to-class="opacity-100 scale-100"
               >
                    <div
                         v-if="checkActionRestricted()"
                         class="rounded-xl !mt-0 bg-primal-yellow text-shade-19 flex flex-row items-center gap-2 ease-in-out transition-all duration-300 p-3 mb-4 w-full"
                    >
                         <svg
                              class="w-5 h-5"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                         >
                              <path
                                   d="M10 7V11M10 14V14.01M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C2.80031 4.47177 2.13738 5.46392 1.68508 6.55585C1.23279 7.64778 1 8.8181 1 10Z"
                                   stroke="#FFF2B1"
                                   stroke-width="2"
                                   stroke-linecap="round"
                                   stroke-linejoin="round"
                              />
                         </svg>
                         <p class="text-sm lg:text-base font-medium">
                              An error has occurred, please contact support.
                         </p>
                    </div>
               </transition>
               <div
                    v-if="walletCount == 0"
                    class="w-full mt-5"
               >
                    <span
                         @click="() => fetchSDK('bank')"
                         class="font-bold underline text-base cursor-pointer text-moozi-1"
                    >
                         <span class="text-lg">+</span>
                         <span class="text-sm xl:text-base">
                              Connect New Account
                         </span>
                    </span>
               </div>
               <button
                    @click="openConfirmationModal()"
                    :disabled="
                         selectedWallet != null &&
                         !checkActionRestricted() &&
                         !(
                              dailyLimit != null &&
                              limitReachValue + coins.total > dailyLimit
                         )
                              ? false
                              : true
                    "
                    :class="
                         selectedWallet != null &&
                         !checkActionRestricted() &&
                         !(
                              dailyLimit != null &&
                              limitReachValue + coins.total > dailyLimit
                         )
                              ? 'bg-secondary-3 text-primary-1 cursor-pointer'
                              : 'bg-gray-190 text-shade-7 cursor-not-allowed'
                    "
                    class="w-full mt-5 rounded-full font-bold tracking-wider px-10 py-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
               >
                    Next
               </button>
          </div>
     </div>
     <WalletConfirmation
          :onClickPurchaseCoin="makePurchase"
          :selectedWallet="selectedWallet ?? {}"
          :coins="coins"
          :purchase_loading="purchase_loading"
          :initRecaptcha="initRecaptcha"
     />
</template>
<script>
import { onMounted, reactive, watch, ref } from "vue";
import {
     initConnectPay as initConnectPayAPI,
     makePurchase as makePurchaseAPI,
     enrollmentLogs as enrollmentLogsAPI
} from "api/connect_pay.js";
import { authStore } from "store/auth.js";
import { computed } from "vue";
import { connectPayStore } from "store/connectPay.js";
import _ from "underscore";
import eventBus from "plugins/event.js";
import WalletConfirmation from "components/modals/walletConfimation.vue";
import { useToaster } from "composables/use-toaster.js";
import { useFormatter } from "composables/use-formatter.js";
import { fiservUrl } from "config/services.js";
import { baseUrl } from "config/services.js";
import { useKYC } from "composables/use-kyc.js";
import { useActionRestriction } from "composables/use-restriction.js";
import { useRouter } from "vue-router";
import { coinsStore } from "store/coins";
import { useGTag } from "composables/use-gtag.js";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-vue-v3";

export default {
     props: [
          "coins",
          "setLoading",
          "setPaymentSuccessCoins",
          "closeModal",
          "gtagSelected"
     ],
     components: {
          WalletConfirmation
     },
     setup(props) {
          const { validateEveryAction } = useKYC();
          const { isActionRestricted } = useActionRestriction();
          const { getData } = useVisitorData(
               { extendedResult: true },
               { immediate: false }
          );

          var extraParams = reactive({
               firstName: "",
               lastName: "",
               email: "",
               street: "",
               city: "",
               state: "",
               postalCode: ""
          });

          const initRecaptcha = ref(false);

          const { gTagAddPaymentInfo, gTagPurchase, gTagSelectItemList } =
               useGTag();
          const { successToast, errorToast } = useToaster();
          const { removeUSAPrefix } = useFormatter();
          const router = useRouter();

          const useAuthStore = authStore();
          const useconnectPayStore = connectPayStore();
          const useCoinsStore = coinsStore();

          const isAddAccountPanelOpen = ref(false);
          const loading = ref(false);

          const purchase_loading = ref(false);

          const modal_loading = ref(false);

          const selectedWallet = ref(null);

          const statusMsg = ["ERROR", "DECLINED"];

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const enrolled = computed(() => {
               return useconnectPayStore.enrolled;
          });

          const billToAddress = computed(() => {
               return useconnectPayStore.billToAddress;
          });

          const walletCount = computed(() => {
               return useconnectPayStore.walletCount;
          });

          const limitReachValue = computed(() => {
               return useCoinsStore.limitReachValue;
          });

          const dailyLimit = computed(() => {
               return useAuthStore.dailyLimit;
          });

          // variable for one time callback

          const allowToAdd = computed(() => {
               return useconnectPayStore.allowToAdd;
          });

          const allowToEdit = computed(() => {
               return useconnectPayStore.allowToEdit;
          });

          const allowToRemove = computed(() => {
               return useconnectPayStore.allowToRemove;
          });

          const getSDKConfig = (data, type, accountId) => {
               const params = {
                    accessToken: data["currentSession"]["security"]["tokenID"],
                    fdCustomerId: ["bank", "manual"].includes(type)
                         ? data["fdCustomerID"]
                         : enrolled.value.customer.fdCustomerID,
                    encryptionKey:
                         data["currentSession"]["security"]["publicKey"]
               };

               switch (type) {
                    case "bank":
                         params["configId"] = fiservUrl.bank;
                         break;
                    case "manual":
                         params["configId"] = fiservUrl.manual;
                         break;
                    case "edit":
                         params["configId"] = fiservUrl.edit;
                         break;
                    case "close":
                         params["configId"] = fiservUrl.close;
                         break;
                    default:
                         break;
               }

               if (accountId != null) {
                    params["accountID"] = accountId;
               }

               return params;
          };

          const setExtraParams = async () => {
               // const data = await getData();

               extraParams.firstName = billToAddress.value.firstName;
               extraParams.lastName = billToAddress.value.lastName;
               extraParams.email = isUser.value.email;
               extraParams["userPhone[0].type"] = "HOME";
               extraParams["userPhone[0].primary"] = "Y";
               extraParams["userPhone[0].number"] = isUser.value.phone;

               extraParams.street = billToAddress.value.addressLine1;
               extraParams.city = billToAddress.value.city;
               extraParams.state = billToAddress.value.state;

               if (billToAddress.value.zip.includes("-")) {
                    extraParams.postalCode =
                         billToAddress.value.zip.split("-")[0];
               } else {
                    extraParams.postalCode = billToAddress.value.zip;
               }

               if (extraParams["userPhone[0].number"]) {
                    extraParams["userPhone[0].number"] = removeUSAPrefix(
                         extraParams["userPhone[0].number"]
                    );
               }

               return extraParams;
          };

          const fetchSDK = async (type, accountId = null) => {
               if (validateEveryAction("add-bank") == true) {
                    return;
               }

               let params = {};
               if (["bank", "manual"].includes(type)) {
                    if (walletCount.value >= 1) {
                         return;
                    }
                    isAddAccountPanelOpen.value = true;
                    loading.value = true;
               } else {
                    params = { fdAccountID: accountId };
               }

               props.setLoading(true);
               modal_loading.value = true;

               await initConnectPayAPI(params)
                    .then(async (res) => {
                         if (res) {
                              let CP = await CPSDK(res["api_key"], true);

                              const sdkConfiguration = getSDKConfig(
                                   res,
                                   type,
                                   accountId
                              );

                              const extraData = await setExtraParams(res);

                              console.log(extraData);

                              if (
                                   extraData["city"] == null ||
                                   extraData["city"] == "" ||
                                   extraData["state"] == null ||
                                   extraData["state"] == "" ||
                                   extraData["postalCode"] == null ||
                                   extraData["postalCode"] == "" ||
                                   extraData["street"] == null ||
                                   extraData["street"] == ""
                              ) {
                                   errorToast(
                                        "Address Incomplete!",
                                        "top-right"
                                   );
                                   props.setLoading(false);
                                   modal_loading.value = false;
                                   return;
                              }

                              if (type == "bank") {
                                   gTagAddPaymentInfo(
                                        props.gtagSelected?.price,
                                        "Bank Transfer",
                                        props.gtagSelected
                                   );
                                   props.setLoading(false);
                                   await addBank(
                                        CP,
                                        sdkConfiguration,
                                        extraData
                                   );
                              } else if (type == "manual") {
                                   props.setLoading(false);
                                   await manualBank(
                                        CP,
                                        sdkConfiguration,
                                        extraData
                                   );
                              } else if (type == "edit") {
                                   props.setLoading(false);
                                   await updateBank(
                                        CP,
                                        sdkConfiguration,
                                        extraData
                                   );
                              } else if (type == "close") {
                                   props.setLoading(false);
                                   await closeBank(
                                        CP,
                                        sdkConfiguration,
                                        extraData
                                   );
                              }
                         }
                    })
                    .catch(() => {
                         props.setLoading(false);
                    });
          };

          const makePurchase = async (accountId, tokenResponse) => {
               const fingerprint_data = await getData();

               purchase_loading.value = true;
               const params = {
                    accountId: accountId
               };

               if (props.coins["action"] == "package") {
                    params["coin_id"] = props.coins["coin_id"];
               } else {
                    params["money"] = parseInt(props.coins["total"]);
               }

               params["g-recaptcha-response"] = tokenResponse;
               if (fingerprint_data) {
                    params["fingerprint"] = fingerprint_data;
               }

               await makePurchaseAPI(params)
                    .then(async (res) => {
                         await useCoinsStore.getLimitReach(true);
                         purchase_loading.value = false;
                         closeConfirmationModal();
                         props.closeModal();

                         const json = JSON.parse(res.notes);

                         openPaymentSuccess(
                              {
                                   gc: res.gold_coins,
                                   sc: res.silver_coins
                              },
                              json.transaction_id
                         );
                         props.setPaymentSuccessCoins(res);
                    })
                    .catch((error) => {
                         const { response } = error || {};
                         const status = response?.status;
                         const data = response?.data;
                         if (status == 403) {
                              window.location.href = "/";
                              return;
                         }

                         purchase_loading.value = false;
                         closeConfirmationModal();
                         props.closeModal();
                         openPaymentFailed(data?.message ?? null);
                    });
          };

          const openPaymentSuccess = (coins, transaction_id) => {
               gTagPurchase(
                    transaction_id,
                    props.gtagSelected?.price,
                    props.gtagSelected
               );
               gTagSelectItemList(props.gtagSelected);
               eventBus.emit("open:payment_success", coins);
          };

          const openPaymentFailed = (message = null) => {
               eventBus.emit("open:payment_failed", message);
          };

          const openBankWallet = () => {
               eventBus.emit("open:bank_wallet");
          };

          const closeBankWallet = () => {
               eventBus.emit("close:bank_wallet");
          };

          const openManualWallet = () => {
               eventBus.emit("open:manual_wallet");
          };

          const closeManualWallet = () => {
               eventBus.emit("close:manual_wallet");
          };

          const openUpdateWallet = () => {
               eventBus.emit("open:update_wallet");
          };

          const closeUpdateWallet = () => {
               eventBus.emit("close:update_wallet");
          };

          const openRemoveWallet = () => {
               eventBus.emit("open:remove_wallet");
          };

          const closeRemoveWallet = () => {
               eventBus.emit("close:remove_wallet");
          };

          const openConfirmationModal = () => {
               if (
                    dailyLimit.value != null &&
                    limitReachValue.value + props.coins.total > dailyLimit.value
               ) {
                    errorToast(
                         "This purchase is beyond your set purchase limit!",
                         "top-right"
                    );
                    return;
               }
               initRecaptcha.value = true;
               eventBus.emit("open:walletconfirmation");
          };

          const closeConfirmationModal = () => {
               initRecaptcha.value = false;
               eventBus.emit("close:walletconfirmation");
          };

          const addBank = async (CP, sdkConfiguration, extraData) => {
               console.log("===============================================");
               console.log("STREAMLINE ENROLLMENT() - LINK BANK ACCOUNT");
               console.log(sdkConfiguration);
               console.log(extraData);
               //    32678005-8e7f-4d12-8298-cd40e103866a
               openBankWallet();
               useconnectPayStore.setPermission("add", true);
               const addBank = await CP.StreamlinedEnrollment(
                    sdkConfiguration,
                    extraData,
                    async function (response) {
                         if (allowToAdd.value) {
                              useconnectPayStore.setPermission("add", false);
                              const res =
                                   typeof response == "object"
                                        ? response
                                        : JSON.parse(response);

                              getEnrollmentLogs(response);

                              if (
                                   res.transactionStatusDescription ==
                                   "User Cancelled"
                              ) {
                                   //   isAddAccountPanelOpen.value = false;
                                   modal_loading.value = true;
                                   closeBankWallet();
                              } else if (
                                   statusMsg.includes(res.transactionStatus)
                              ) {
                                   console.error(
                                        res.transactionStatusDescription
                                   );
                                   errorToast(
                                        res.transactionStatusDescription,
                                        "top-right"
                                   );
                                   modal_loading.value = true;
                                   fetchSDK("bank");
                              } else {
                                   successToast(
                                        "Successfully Linked Bank Account!",
                                        "top-right"
                                   );

                                   purchase_loading.value = true;
                                   const saveOldEnrolledList =
                                        enrolled.value?.account;
                                   await useconnectPayStore.getEnrolled(true);
                                   await useconnectPayStore.getWalletRedeem(
                                        true
                                   );
                                   const newEnrolledList =
                                        enrolled.value?.account;

                                   const pluckOld = _.pluck(
                                        saveOldEnrolledList,
                                        "fdAccountID"
                                   );
                                   const pluckNew = _.pluck(
                                        newEnrolledList,
                                        "fdAccountID"
                                   );

                                   closeBankWallet();
                                   purchase_loading.value = false;

                                   const dif = _.difference(pluckNew, pluckOld);
                                   console.log(dif);

                                   const filter = _.filter(
                                        newEnrolledList,
                                        function (params) {
                                             return (
                                                  params["fdAccountID"] ==
                                                  dif[0]
                                             );
                                        }
                                   );

                                   setSelectedWallet(filter[0]);
                                   openConfirmationModal();
                              }
                         }
                    }
               );

               addBank.start("sdk-container-bank");
               loading.value = false;
          };

          const manualBank = async (CP, sdkConfiguration, extraData) => {
               console.log("===============================================");
               console.log("STREAMLINE ENROLLMENT() - MANUAL");
               console.log(sdkConfiguration);
               console.log(extraData);
               openManualWallet();
               useconnectPayStore.setPermission("add", true);
               const addBank = await CP.StreamlinedEnrollment(
                    sdkConfiguration,
                    extraData,
                    async function (response) {
                         if (allowToAdd.value) {
                              useconnectPayStore.setPermission("add", false);

                              const res =
                                   typeof response == "object"
                                        ? response
                                        : JSON.parse(response);

                              getEnrollmentLogs(response);

                              if (
                                   res.transactionStatusDescription ==
                                   "User Cancelled"
                              ) {
                                   //   isAddAccountPanelOpen.value = false;
                                   closeManualWallet();
                              } else if (
                                   statusMsg.includes(res.transactionStatus)
                              ) {
                                   console.error(
                                        res.transactionStatusDescription
                                   );
                                   errorToast(
                                        res.transactionStatusDescription,
                                        "top-right"
                                   );
                                   fetchSDK("manual");
                              } else {
                                   console.log(response);
                                   successToast(
                                        "Successfully Enrolled!",
                                        "top-right"
                                   );
                                   await useconnectPayStore.getEnrolled(true);
                                   await useconnectPayStore.getWalletRedeem(
                                        true
                                   );
                              }
                         }
                    }
               );

               addBank.start("sdk-container-manual");
               loading.value = false;
          };

          const updateBank = async (CP, sdkConfiguration, extraData) => {
               console.log("===============================================");
               console.log("UPDATEENROLLMENT()");
               console.log(sdkConfiguration);
               console.log(extraData);
               openUpdateWallet();
               useconnectPayStore.setPermission("edit", true);
               const editBank = await CP.UpdateEnrollment(
                    sdkConfiguration,
                    extraData,
                    async function (response) {
                         if (allowToEdit.value) {
                              useconnectPayStore.setPermission("edit", false);

                              const res =
                                   typeof response == "object"
                                        ? response
                                        : JSON.parse(response);

                              if (
                                   res.transactionStatusDescription ==
                                   "User Cancelled"
                              ) {
                                   closeUpdateWallet();
                              } else if (
                                   statusMsg.includes(res.transactionStatus)
                              ) {
                                   console.error(
                                        res.transactionStatusDescription
                                   );
                                   errorToast(
                                        res.transactionStatusDescription,
                                        "top-right"
                                   );
                                   fetchSDK("edit");
                              } else {
                                   console.log(response);
                                   successToast(
                                        "Successfully Updated!",
                                        "top-right"
                                   );
                              }
                         }
                    }
               );

               editBank.start("sdk-container-update");
               loading.value = false;
          };

          const closeBank = async (CP, sdkConfiguration, extraData) => {
               console.log("===============================================");
               console.log("CLOSEACCOUNT()");
               console.log(sdkConfiguration);
               console.log(extraData);
               openRemoveWallet();
               useconnectPayStore.setPermission("remove", true);
               const removeBank = await CP.CloseAccount(
                    sdkConfiguration,
                    extraData,
                    async function (response) {
                         if (allowToRemove.value) {
                              useconnectPayStore.setPermission("remove", false);

                              const res =
                                   typeof response == "object"
                                        ? response
                                        : JSON.parse(response);

                              if (
                                   res.transactionStatusDescription ==
                                   "User Cancelled"
                              ) {
                                   closeRemoveWallet();
                              } else if (
                                   statusMsg.includes(res.transactionStatus)
                              ) {
                                   console.error(
                                        res.transactionStatusDescription
                                   );
                                   errorToast(
                                        res.transactionStatusDescription,
                                        "top-right"
                                   );
                                   fetchSDK("close");
                              } else {
                                   console.log(response);
                                   purchase_loading.value = true;
                                   successToast(
                                        "Successfully Closed!",
                                        "top-right"
                                   );
                                   await useconnectPayStore.getEnrolled(true);
                                   await useconnectPayStore.getWalletRedeem(
                                        true
                                   );
                                   purchase_loading.value = false;
                                   closeRemoveWallet();
                              }
                         }
                    }
               );

               removeBank.start("sdk-container-remove");
               loading.value = false;
          };

          const getEnrollmentLogs = async (params) => {
               const temp = {
                    response_data: JSON.parse(params)
               };

               console.log(JSON.parse(params));

               await enrollmentLogsAPI(temp)
                    .then(() => {
                         console.log("successfully log");
                    })
                    .catch((error) => {
                         console.error(error);
                    });
          };

          const setSelectedWallet = (data) => {
               selectedWallet.value = data;
               gTagAddPaymentInfo(
                    props.gtagSelected?.price,
                    "Bank Transfer",
                    props.gtagSelected
               );
          };

          const getActiveAccount = (data = []) => {
               const temp = _.filter(data, function (params) {
                    return params["accountStatus"] != "40";
               });

               return temp.length != 0 ? temp : [];
          };

          const checkAPButton = async () => {
               const apButtonTab = document.getElementById("ap-button-tab");
               const apButtonPanel = document.getElementById("ap-button-panel");
               if (apButtonTab && apButtonPanel) {
                    if (
                         window?.ApplePaySession &&
                         ApplePaySession?.canMakePayments()
                    ) {
                         // apButtonTab?.classList.remove("hidden");
                         // apButtonPanel?.classList.remove("hidden");
                    } else {
                         apButtonTab?.remove();
                         apButtonPanel?.remove();
                    }
               }
          };

          const checkActionRestricted = () => {
               // DEPOSIT
               const restrictedActionType = 1;

               return isActionRestricted(restrictedActionType);
          };

          const gotoResponsiblePlay = () => {
               router.push({
                    path: "/account",
                    query: { tab: "responsible-play" },
                    force: true
               });
          };

          onMounted(async () => {
               // setTimeout(() => {
               checkAPButton();
               // }, 500);

               const isExist = document.querySelector("#fiserv-import");
               if (isExist == null) {
                    var script = document.createElement("script");
                    script.id = "fiserv-import";
                    script.src =
                         import.meta.env.VITE_ENV == "production"
                              ? "https://prod.api.firstdata.com/gateway/v2/connectpay/static/v1/js/PaymentSDK.js"
                              : "https://cat.api.firstdata.com/gateway/v2/connectpay/static/v1/js/PaymentSDK.js";
                    document.body.appendChild(script);
               }

               loading.value = true;
               await useconnectPayStore.getEnrolled();
               if (enrolled.value != null && enrolled.value?.account?.length) {
                    if (
                         walletCount.value == 0 &&
                         enrolled.value?.account[0].accountStatus == "40"
                    )
                         return;
                    else {
                         const temp = _.filter(
                              enrolled.value?.account,
                              function (params) {
                                   return params["accountStatus"] != "40";
                              }
                         );

                         if (temp.length != 0) {
                              selectedWallet.value = temp[0];
                              gTagAddPaymentInfo(
                                   props.gtagSelected?.price,
                                   "Bank Transfer",
                                   props.gtagSelected
                              );
                         } else {
                              selectedWallet.value = null;
                         }
                    }
               }
               await useAuthStore.getUser();
               loading.value = false;
          });

          watch(isUser, (newVal) => {
               if (newVal) {
                    console.log(newVal);
               }
          });

          // watch(isUser, (newVal) => {
          //      if (newVal) {
          //           console.log(newVal);
          //      }
          // });

          return {
               enrolled,
               walletCount,
               fetchSDK,
               isAddAccountPanelOpen,
               loading,
               makePurchase,
               purchase_loading,
               selectedWallet,
               setSelectedWallet,
               openConfirmationModal,
               baseUrl,
               getActiveAccount,
               removeUSAPrefix,
               checkActionRestricted,
               initRecaptcha,
               gotoResponsiblePlay,
               limitReachValue,
               dailyLimit
          };
     }
};
</script>

<template>
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isOpenConfirmation"
               id="authentication-modal"
               tabindex="-1"
               aria-hidden="true"
               class="overflow-y-auto overflow-x-hidden fixed z-80 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0 font-helveticaLight flex justify-center items-center"
          >
               <div
                    class="relative px-6 py-3 sm:py-4 sm:px-10 w-full max-w-lg max-h-full m-auto"
               >
                    <!-- Modal content -->
                    <div class="relative bg-shade-1 rounded-xl shadow-xl">
                         <button
                              @click="closeModal(setConfirmationFunc)"
                              type="button"
                              class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                              data-modal-hide="authentication-modal"
                         >
                              <img
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/exclude.svg'
                                   "
                                   alt=""
                              />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <div
                              class="flex flex-col divide-y divide-shade-12 items-center justify-center py-6 lg:py-8 font-roboto text-shade-2 font-bold text-sm xs:text-base px-4 sm:px-6 lg:px-8 tracking-wide leading-tight"
                         >
                              <div class="pb-4 w-full text-center">
                                   <h3
                                        class="text-base xs:text-lg font-bold text-gray-900"
                                   >
                                        Please confirm your payment details
                                   </h3>
                              </div>
                              <div class="py-2 xs:py-4 w-full">
                                   <div
                                        class="flex flex-col gap-2 sm:gap-4 lg:gap-6 px-2"
                                   >
                                        <div class="uppercase mb-1 xs:mb-3">
                                             Credit Card Details
                                        </div>
                                        <div
                                             v-if="
                                                  getCardName(
                                                       selectedCard?.paypage
                                                            ?.type ||
                                                            wpResponse?.type
                                                  ) === 'MasterCard'
                                             "
                                             class="rounded-xl !-mt-5 bg-primal-yellow text-shade-19 flex flex-row items-center gap-2 ease-in-out transition-all duration-300 p-3 mb-2 w-full"
                                        >
                                             <svg
                                                  class="w-5 h-5"
                                                  viewBox="0 0 20 20"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                             >
                                                  <path
                                                       d="M10 7V11M10 14V14.01M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C2.80031 4.47177 2.13738 5.46392 1.68508 6.55585C1.23279 7.64778 1 8.8181 1 10Z"
                                                       stroke="#FFF2B1"
                                                       stroke-width="2"
                                                       stroke-linecap="round"
                                                       stroke-linejoin="round"
                                                  />
                                             </svg>
                                             <p
                                                  class="text-sm lg:text-sm font-medium"
                                             >
                                                  MasterCard is currently not
                                                  supported.
                                             </p>
                                        </div>
                                        <p
                                             v-if="
                                                  getCardName(
                                                       selectedCard?.paypage
                                                            ?.type ||
                                                            wpResponse?.type
                                                  ) === 'MasterCard'
                                             "
                                             class="font-roboto text-shade-7 text-center text-xs lg:text-xs -mt-5"
                                        >
                                             MasterCard is supported through
                                             ApplePay.
                                        </p>
                                        <div
                                             class="flex flex-wrap items-start gap-2 sm:gap-3 lg:gap-4"
                                        >
                                             <div
                                                  class="flex-1 flex flex-col items-start"
                                             >
                                                  <span class="text-shade-7"
                                                       >Card holder's name</span
                                                  >
                                                  <span>{{
                                                       Object.keys(selectedCard)
                                                            .length != 0
                                                            ? selectedCard?.card_name
                                                                 ? selectedCard?.card_name
                                                                 : "-"
                                                            : payment?.card_name
                                                              ? payment?.card_name
                                                              : "-"
                                                  }}</span>
                                             </div>
                                             <div
                                                  class="flex-1 flex flex-col items-start"
                                             >
                                                  <span class="text-shade-7"
                                                       >Card type</span
                                                  >
                                                  <span>{{
                                                       Object.keys(selectedCard)
                                                            .length != 0
                                                            ? getCardName(
                                                                   selectedCard
                                                                        ?.paypage
                                                                        ?.type
                                                              )
                                                            : getCardName(
                                                                   wpResponse?.type
                                                              )
                                                  }}</span>
                                             </div>
                                        </div>
                                        <div
                                             class="flex flex-wrap items-start gap-2 sm:gap-3 lg:gap-4"
                                        >
                                             <div
                                                  class="flex-1 flex flex-col items-start"
                                             >
                                                  <span class="text-shade-7"
                                                       >Card number</span
                                                  >
                                                  <span
                                                       >************{{
                                                            Object.keys(
                                                                 selectedCard
                                                            ).length != 0
                                                                 ? selectedCard
                                                                        ?.paypage
                                                                        ?.lastFour
                                                                 : wpResponse?.lastFour
                                                       }}</span
                                                  >
                                             </div>
                                             <div
                                                  class="flex-1 flex flex-col items-start"
                                             >
                                                  <span class="text-shade-7"
                                                       >Expiry Date</span
                                                  >
                                                  <span>
                                                       {{
                                                            Object.keys(
                                                                 selectedCard
                                                            ).length != 0
                                                                 ? selectedCard
                                                                        .paypage
                                                                        ?.card_expiration
                                                                 : (wpResponse?.expMonth
                                                                        ? wpResponse?.expMonth
                                                                        : "-") +
                                                                   (wpResponse?.expYear
                                                                        ? "/20" +
                                                                          wpResponse?.expYear
                                                                        : "/-")
                                                       }}</span
                                                  >
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div
                                   class="pt-2 xs:pt-4 text-center w-full font-helveticaLight"
                              >
                                   <div class="pb-4 xs:pb-6 px-2">
                                        <div
                                             class="text-xs xs:text-sm flex flex-wrap items-center font-normal"
                                        >
                                             <div
                                                  class="flex-1 flex flex-col items-start"
                                             >
                                                  <span
                                                       class="text-base xs:text-lg lg:text-xl font-bold"
                                                       >Total</span
                                                  >
                                             </div>
                                             <div
                                                  class="flex-1 flex flex-col items-end"
                                             >
                                                  <!-- <span>${{ money(coins?.net_amount) }}</span>
                                                  <span>-{{coins?.discount}}%</span> -->
                                                  <!-- <span class="text-base xs:text-lg lg:text-xl font-bold">${{ getPercentage(coins?.discount, coins?.net_amount) }}</span> -->
                                                  <span
                                                       class="text-base xs:text-lg lg:text-xl font-bold"
                                                       >${{
                                                            money(coins?.total)
                                                       }}</span
                                                  >
                                             </div>
                                        </div>
                                   </div>
                                   <div class="my-5 flex justify-center w-full">
                                        <vue-recaptcha
                                             v-if="initRecaptcha"
                                             :sitekey="VITE_RECAPTCHA_SITE_KEY"
                                             @verify="verifyMethod"
                                             @expired="expiredMethod"
                                             @render="renderMethod"
                                             @error="errorMethod"
                                        ></vue-recaptcha>
                                   </div>
                                   <div class="px-2">
                                        <button
                                             :disabled="
                                                  tokenResponse == null ||
                                                  getCardName(
                                                       selectedCard?.paypage
                                                            ?.type ||
                                                            wpResponse?.type
                                                  ) === 'MasterCard'
                                             "
                                             :class="
                                                  tokenResponse &&
                                                  getCardName(
                                                       selectedCard?.paypage
                                                            ?.type ||
                                                            wpResponse?.type
                                                  ) !== 'MasterCard'
                                                       ? 'text-primary-1 bg-secondary-3'
                                                       : 'text-white bg-gray-190 opacity-70 cursor-not-allowed'
                                             "
                                             @click="payNow()"
                                             class="w-full px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold flex justify-center items-center"
                                        >
                                             Pay Now
                                        </button>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </transition>
     <loading-overlay :isLoading="purchase_loading" />
     <div
          v-if="isOpenConfirmation"
          class="fixed inset-0 z-60"
          style="background-color: rgba(0, 0, 0, 0.3)"
     ></div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import eventBus from "plugins/event.js";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { utilitiesStore } from "store/utilities.js";
import { useFormatter } from "composables/use-formatter.js";
import { baseUrl } from "config/services.js";
const VITE_RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;
import { VueRecaptcha } from "vue-recaptcha";

export default {
     props: {
          coins: {
               type: Object,
               required: true
          },
          selectedCard: {
               type: Object,
               required: true
          },
          onClickPurchaseCoin: {
               type: Function,
               required: true
          },
          purchase_loading: {
               type: Boolean,
               required: true
          },
          wpResponse: {
               type: Object,
               required: true
          },
          payment: {
               type: Object,
               required: true
          },
          initRecaptcha: {
               type: Boolean,
               required: true
          },
          setConfirmationFunc: {
               type: Function
          }
     },
     components: {
          LoadingOverlay,
          VueRecaptcha
     },
     setup(props) {
          const { money } = useFormatter();
          const isOpenConfirmation = ref(false);
          const loading = ref(false);
          const useUtilitiesStore = utilitiesStore();

          const tokenResponse = ref(null);

          const toggleCreditConfirmation = () => {
               isOpenConfirmation.value = !isOpenConfirmation.value;
          };

          const closeModal = (data) => {
               if (data) {
                    data();
               }
               tokenResponse.value = null;
               isOpenConfirmation.value = false;
               useUtilitiesStore.enableScroll();
          };

          const payNow = () => {
               if (tokenResponse.value) {
                    props.onClickPurchaseCoin(tokenResponse.value);
               }
          };

          const getPercentage = (discount, amount) => {
               return money(amount - (discount / 100) * amount);
          };

          const getCardName = (type) => {
               return useUtilitiesStore.getCardTypeName(type);
          };

          const verifyMethod = async (response) => {
               tokenResponse.value = response;
          };

          const expiredMethod = async () => {
               tokenResponse.value = null;
               console.error("expired");
          };

          const renderMethod = async () => {};

          const errorMethod = async () => {
               tokenResponse.value = null;
               console.error("error");
          };

          onMounted(async () => {
               eventBus.on("open:creditconfirmation", () => {
                    useUtilitiesStore.disableScroll();
                    toggleCreditConfirmation();
               });
               eventBus.on("close:creditconfirmation", () => {
                    useUtilitiesStore.enableScroll();
                    toggleCreditConfirmation();
               });
          });

          onUnmounted(() => {
               eventBus.off("open:creditconfirmation");
               eventBus.off("close:creditconfirmation");
          });

          return {
               loading,
               isOpenConfirmation,
               closeModal,
               payNow,
               money,
               getPercentage,
               getCardName,
               baseUrl,
               VITE_RECAPTCHA_SITE_KEY,
               verifyMethod,
               expiredMethod,
               renderMethod,
               errorMethod,
               tokenResponse
          };
     }
};
</script>

<template>
     <form
          @submit.prevent="profileUpdate"
          class="h-full flex flex-col justify-between"
     >
          <div class="space-y-3">
               <div>
                    <label
                         for="email"
                         class="block mb-1 font-roboto text-sm text-moozi-1 font-bold"
                         >Email Address
                    </label>
                    <input
                         v-model="profileFormModel.email"
                         type="email"
                         name="email"
                         id="email"
                         class="bg-white border border-shade-10 text-shade-2 text-sm rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 font-normal"
                         placeholder="Enter your email"
                         autocomplete="off"
                         autocorrect="off"
                         readonly
                         @change="clearFormError('email')"
                         :class="
                              profile_form.errors?.errors?.email != null ||
                              !isUser?.email_verified_at
                                   ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                   : 'border-shade-10 focus:ring-background-2 focus:border-background-2'
                         "
                    />
                    <has-error-form
                         v-model:form="profile_form"
                         :field="'profile_form'"
                    ></has-error-form>
                    <transition
                         enter-from-class="opacity-0 -translate-y-1.5"
                         enter-active-class="transition ease-in duration-300"
                         enter-to-class="opacity-100 translate-y-0"
                         leave-from-class="opacity-100 translate-y-0"
                         leave-active-class="transition ease-out duration-300"
                         leave-to-class="opacity-0 -translate-y-1.5"
                    >
                         <div
                              v-if="!isUser?.email_verified_at"
                              class="mt-1 text-sm text-red-600 text-primal-red ease-in-out transition-all duration-300"
                         >
                              Your email is not yet verified.
                              <span
                                   class="text-xs cursor-pointer text-primal-red underline italic uppercase"
                                   @click="resendVerification()"
                                   >Resend Verification</span
                              >
                         </div>
                    </transition>
               </div>

               <div class="flex items-start gap-3">
                    <div class="flex-1">
                         <label
                              for="first_name"
                              class="block mb-1 font-roboto text-sm text-moozi-1 font-bold"
                              >First name</label
                         >
                         <input
                              v-model="profileFormModel.first_name"
                              type="text"
                              name="first_name"
                              id="first_name"
                              required
                              class="bg-white border border-shade-10 text-shade-2 text-sm rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 font-normal"
                              placeholder="Enter your first name"
                              @change="clearFormError('first_name')"
                              :class="
                                   profile_form.errors?.errors?.first_name !=
                                   null
                                        ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                        : 'border-shade-10 focus:ring-background-2 focus:border-background-2'
                              "
                              autocomplete="off"
                              autocorrect="off"
                         />
                         <has-error-form
                              v-model:form="profile_form"
                              :field="'first_name'"
                         ></has-error-form>
                    </div>
                    <div class="flex-1">
                         <label
                              for="last_name"
                              class="block mb-1 font-roboto text-sm text-moozi-1 font-bold"
                              >Last name</label
                         >
                         <input
                              v-model="profileFormModel.last_name"
                              type="text"
                              name="last_name"
                              id="last_name"
                              required
                              class="bg-white border border-shade-10 text-shade-2 text-sm rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 font-normal"
                              placeholder="Enter your last name"
                              @change="clearFormError('last_name')"
                              :class="
                                   profile_form.errors?.errors?.last_name !=
                                   null
                                        ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                        : 'border-shade-10 focus:ring-background-2 focus:border-background-2'
                              "
                              autocomplete="off"
                              autocorrect="off"
                         />
                         <has-error-form
                              v-model:form="profile_form"
                              :field="'last_name'"
                         ></has-error-form>
                    </div>
               </div>
               <div class="flex-1">
                    <label
                         for="phone"
                         class="block mb-1 font-roboto text-sm text-moozi-1 font-bold"
                         >Phone number</label
                    >
                    <div
                         class="reg-phone-c"
                         :class="
                              profile_form.errors?.errors?.phone != null ||
                              (!isValidPhoneNumber &&
                                   isValidPhoneNumber != null)
                                   ? 'error'
                                   : ''
                         "
                    >
                         <vue-tel-input
                              v-model="set_phone_form"
                              mode="international"
                              :defaultCountry="profile_form.phone_area"
                              :autoFormat="false"
                              :dropdownOptions="{
                                   showDialCodeInList: true,
                                   showDialCodeInSelection: true,
                                   showFlags: true,
                                   showSearchBox: true
                              }"
                              @validate="onValidatePhone"
                              @on-input="handlePhoneNumberInput"
                              @country-changed="onCountryChanged"
                              autocomplete="off"
                              autocorrect="off"
                         ></vue-tel-input>
                    </div>
                    <has-error-form
                         v-model:form="profile_form"
                         :field="'phone'"
                    ></has-error-form>
                    <transition
                         enter-from-class="opacity-0 -translate-y-1.5"
                         enter-active-class="transition ease-in duration-300"
                         enter-to-class="opacity-100 translate-y-0"
                         leave-from-class="opacity-100 translate-y-0"
                         leave-active-class="transition ease-out duration-300"
                         leave-to-class="opacity-0 -translate-y-1.5"
                    >
                         <div
                              v-if="
                                   profile_form.errors?.errors?.phone == null &&
                                   !isValidPhoneNumber &&
                                   isValidPhoneNumber != null
                              "
                              class="mt-1 text-sm text-red-600 text-primal-red ease-in-out transition-all duration-300"
                         >
                              Invalid Phone number
                         </div>
                    </transition>
               </div>

               <div class="flex-1">
                    <label
                         for="address_1"
                         class="block mb-1 font-roboto text-sm text-moozi-1 font-bold"
                         >Address line</label
                    >
                    <input
                         v-model="query"
                         type="text"
                         name="address_1"
                         id="address_1"
                         required
                         class="bg-white border border-shade-10 text-shade-2 text-sm rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 font-normal"
                         placeholder="Enter your address"
                         @change="clearFormError('address_1')"
                         @input="onInput"
                         :class="
                              profile_form.errors?.errors?.address_1 != null
                                   ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                   : 'border-shade-10 focus:ring-background-2 focus:border-background-2'
                         "
                         autocomplete="off"
                         autocorrect="off"
                    />
                    <ul
                         v-if="showSuggestions"
                         class="autocomplete-suggestions"
                    >
                         <li
                              v-for="(suggestion, index) in suggestions"
                              :key="index"
                              @click="selectSuggestion(suggestion)"
                              class="suggestion-item font-normal text-sm"
                         >
                              {{ suggestion.description }}
                         </li>
                    </ul>
                    <has-error-form
                         v-model:form="profile_form"
                         :field="'address_1'"
                    ></has-error-form>
               </div>

               <div class="flex items-start gap-3">
                    <div class="flex-1">
                         <label
                              for="province_id"
                              class="block mb-1 font-roboto text-sm text-moozi-1 font-bold"
                              >State</label
                         >
                         <searchableSelect
                              :form="profileFormModel"
                              :options="state"
                              v-model:selectedOption="
                                   profileFormModel.address.province_id
                              "
                              :optionLabel="'province_name'"
                              :optionValue="'id'"
                              :placeholder="'Select state'"
                         />
                         <has-error-form
                              v-model:form="profile_form"
                              :field="'province_id'"
                         ></has-error-form>
                    </div>
                    <div class="flex-1">
                         <label
                              for="city_id"
                              class="block mb-1 font-roboto text-sm text-moozi-1 font-bold"
                              >City</label
                         >
                         <input
                              v-model="profileFormModel.address.city_id"
                              type="text"
                              name="city_id"
                              id="city_id"
                              class="bg-white border border-shade-10 text-shade-2 text-sm rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 font-normal"
                              placeholder="Zip Code"
                              @change="clearFormError('city_id')"
                              :class="
                                   profile_form.errors?.errors?.city_id != null
                                        ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                        : 'border-shade-10 focus:ring-background-2 focus:border-background-2'
                              "
                              autocomplete="off"
                              autocorrect="off"
                         />
                         <!-- <searchableSelect
                              :form="profileFormModel"
                              :options="city"
                              v-model:selectedOption="
                                   profileFormModel.address.city_id
                              "
                              :optionLabel="'city_name'"
                              :optionValue="'city_name'"
                              :placeholder="'Select city'"
                         /> -->
                         <has-error-form
                              v-model:form="profile_form"
                              :field="'city_id'"
                         ></has-error-form>
                    </div>
                    <div class="flex-1">
                         <label
                              for="zip_code"
                              class="block mb-1 font-roboto text-sm text-moozi-1 font-bold"
                              >Zip code</label
                         >
                         <input
                              v-model="profileFormModel.address.zip_code"
                              type="text"
                              name="zip_code"
                              id="zip_code"
                              class="bg-white border border-shade-10 text-shade-2 text-sm rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 font-normal"
                              placeholder="Zip Code"
                              @change="clearFormError('zip_code')"
                              :class="
                                   profile_form.errors?.errors?.zip_code != null
                                        ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                        : 'border-shade-10 focus:ring-background-2 focus:border-background-2'
                              "
                              autocomplete="off"
                              autocorrect="off"
                         />
                         <has-error-form
                              v-model:form="profile_form"
                              :field="'zip_code'"
                         ></has-error-form>
                    </div>
               </div>

               <div class="flex-1">
                    <label
                         for="birthday"
                         class="block mb-1 font-roboto text-sm text-moozi-1 font-bold"
                         >Date of birth</label
                    >
                    <input
                         v-model="profileFormModel.birthday"
                         type="date"
                         name="birthday"
                         id="birthday"
                         class="bg-white border border-shade-10 text-shade-2 text-sm rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 font-normal"
                         placeholder="MM/DD/YYY"
                         :max="eighteenYearsAgo()"
                         @change="clearFormError('birthday')"
                         :class="
                              profile_form.errors?.errors?.birthday != null
                                   ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                   : !isBdayChangable
                                     ? 'border-shade-10 bg-gray-20 cursor-not-allowed focus:ring-background-2 focus:border-background-2'
                                     : 'border-shade-10 focus:ring-background-2 focus:border-background-2'
                         "
                         :disabled="!isBdayChangable"
                         :readonly="!isBdayChangable"
                         autocomplete="off"
                         autocorrect="off"
                    />
                    <has-error-form
                         v-model:form="profile_form"
                         :field="'birthday'"
                    ></has-error-form>
                    <span
                         v-if="isUnder18"
                         class="text-primal-red text-xs"
                    >
                         You must be 18+ years old to use our service.
                    </span>
               </div>
          </div>
          <div class="pt-4 sm:pt-6 lg:pt-8 text-center">
               <button
                    type="submit"
                    class="w-full px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full bg-secondary-3 text-primary-1 text-sm font-extrabold"
               >
                    Save
               </button>
               <!-- <div
                    @click="openDeleteConfirmation()"
                    class="w-full mt-5 cursor-pointer px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full bg-white border-2 border-solid border-red-10 text-red-10 text-sm font-extrabold"
               >
                    Close Account
               </div> -->
          </div>
     </form>
     <ModalDeleteAccountConfirmation
          :onClickDelete="() => {}"
          :email="profileFormModel.email"
     />
</template>

<script>
import { onMounted, ref, reactive, computed, watch } from "vue";
import { useFormatter } from "composables/use-formatter.js";
import Form from "vform";
import { authStore } from "@/store/auth";
import { utilitiesStore } from "store/utilities.js";
import {
     getCity as getCityAPI,
     getZipCode as getZipCodeAPI
} from "api/utility.js";
import _ from "underscore";
import HasErrorForm from "components/haserror/index.vue";
import searchableSelect from "components/searchableselect/index.vue";
import ModalDeleteAccountConfirmation from "components/modals/deleteAccountConfirmation.vue";
import eventBus from "plugins/event.js";
import { usePlacesAutocomplete } from "vue-use-places-autocomplete";

export default {
     props: {
          isUser: {
               type: Object,
               required: true
          },
          loading: {
               type: Boolean,
               required: true
          },
          profileUpdate: {
               required: true
          }
     },
     components: {
          HasErrorForm,
          searchableSelect,
          ModalDeleteAccountConfirmation
     },
     setup(props, { emit }) {
          const { eighteenYearsAgo, capitalizeWords } = useFormatter();
          const useAuthStore = authStore();
          const useUtilitiesStore = utilitiesStore();

          const city = ref([]);
          const zipCode = ref([]);

          const stateVal = ref(null);
          // const cityVal = ref(null);

          const isValidPhoneNumber = ref(null);

          const emailVerificationResendForm = reactive(
               new Form({
                    email: props.isUser?.email ? props.isUser.email : ""
               })
          );

          const set_phone_form = computed(() => {
               return useAuthStore.set_phone_form;
          });

          const isBdayChangable = computed(() => {
               return useAuthStore.isBdayChangable;
          });

          const set_phone_area = computed({
               get() {
                    return useAuthStore.set_phone_area;
               },
               // setter
               set(newValue) {
                    useAuthStore.updatePhoneAreaValue(newValue);
               }
          });

          const profileFormModel = computed({
               get: () => useAuthStore.profile_form,
               set: () => {}
          });

          const isUnder18 = computed(() => {
               const birthday = new Date(profileFormModel.value.birthday);
               const eighteenYearsAgoDate = new Date(eighteenYearsAgo());
               return birthday > eighteenYearsAgoDate;
          });

          const error_msg = computed(() => {
               return useAuthStore.error_msg;
          });

          const profile_form = computed(() => {
               return useAuthStore.profile_form;
          });

          const state = computed(() => {
               return useUtilitiesStore.state;
          });

          const resendVerification = () => {
               emit("update:loading", true);
               useAuthStore
                    .emailVerificationResend(emailVerificationResendForm)
                    .then(() => {
                         emit("update:loading", false);
                    })
                    .catch(() => {
                         emit("update:loading", false);
                    });
          };

          async function getCity(id) {
               await getCityAPI({ province_id: id }).then((res) => {
                    city.value = res;
               });
          }

          async function getZipCode(id, cityName) {
               const filter = _.filter(city.value, function (params) {
                    return params["city_name"] == cityName;
               });

               if (filter[0]?.county) {
                    const params = {
                         city_name: cityName,
                         county: filter[0]?.county,
                         province_id: id
                    };

                    await getZipCodeAPI(params).then((res) => {
                         zipCode.value = res;
                    });
               }
          }

          const NumbersOnly = (e) => {
               if (e.key === ".") return e.preventDefault();
               if (e.key === "e") return e.preventDefault();

               e = e ? e : window.event;
               var charCode = e.which ? e.which : e.keyCode;
               if (
                    charCode > 31 &&
                    (charCode < 48 || charCode > 57) &&
                    charCode !== 46
               ) {
                    e.preventDefault();
               } else {
                    return true;
               }
          };

          const handlePhoneNumberInput = (newPhoneNumber) => {
               useAuthStore.updatePhoneFormValue(newPhoneNumber);
          };

          const onValidatePhone = (value) => {
               isValidPhoneNumber.value = value.valid;
          };

          const onCountryChanged = (value) => {
               useAuthStore.updateSelectedCountry(value);
               useAuthStore.updatePhoneAreaValue(value.iso2);
               useAuthStore.updateProfileFormValue("phone_area", value.iso2);
          };

          const clearFormError = (key) => {
               useAuthStore.clearCurrentFormError(key);
          };

          const openDeleteConfirmation = () => {
               eventBus.emit("open:creditdeleteconfirmation");
          };

          const closeDeleteConfirmation = () => {
               eventBus.emit("close:creditdeleteconfirmation");
          };

          watch(error_msg, (newVal) => {
               if (newVal) {
                    useAuthStore.updateProfileFormValue("errors", newVal);
               }
          });

          watch(useAuthStore.profile_form, async (newVal) => {
               stateVal.value = newVal.address.province_id;
               // cityVal.value = newVal.address.city_id;
          });

          watch(stateVal, async (newVal) => {
               if (newVal) {
                    getCity(newVal);
                    // useAuthStore.updateAddressCity("");
                    // zipCode.value = [];
                    // useAuthStore.updateAddressZipcode("");
               } else {
                    useAuthStore.updateAddressCity("");
                    zipCode.value = [];
                    useAuthStore.updateAddressZipcode("");
                    city.value = [];
               }
          });

          // watch(cityVal, async (newVal) => {
          //      if (newVal) {
          //           if (stateVal.value != null) {
          //                getZipCode(stateVal.value, newVal);
          //                // useAuthStore.updateAddressZipcode("");
          //           }
          //      } else {
          //           if (stateVal.value != null) {
          //                useAuthStore.updateAddressZipcode("");
          //                zipCode.value = [];
          //           }
          //      }
          // });

          // Place Autocomplete setup
          const suggestions = ref([]);
          const query = ref("");
          const hasInteracted = ref(false); // Flag to track user interaction

          const { suggestions: autocompleteSuggestions } =
               usePlacesAutocomplete(query, {
                    apiKey: "AIzaSyCmikijM-cNaW7wnWq20K0-FyaThEDScAw", // Replace with your API key
                    minLengthAutocomplete: 3,
                    query: query,
                    autocompletionRequest: {
                         componentRestrictions: {
                              country: "US"
                         }
                    },
                    debounce: 300,
                    withSessionToken: true
               });

          const onInput = (event) => {
               hasInteracted.value = true; // User has interacted
               query.value = event.target.value;
               useAuthStore.updateProfileFormValue("address", {
                    ...profileFormModel.value.address,
                    address_1: query.value
               });
               suggestions.value = [];
          };

          // const onFocus = () => {
          //      query.value = profileFormModel.value.address.address_1 || ""; // Set when focused
          // };

          const handleOutsideClick = (event) => {
               const inputElement = document.getElementById("address_1");
               const dropdownElement = document.querySelector(
                    ".autocomplete-suggestions"
               );

               // Close dropdown if the click is outside the input and suggestions
               if (
                    inputElement &&
                    !inputElement.contains(event.target) &&
                    dropdownElement &&
                    !dropdownElement.contains(event.target)
               ) {
                    suggestions.value = []; // Clear suggestions
               }
          };

          watch(autocompleteSuggestions, (newSuggestions) => {
               suggestions.value = newSuggestions;
               newSuggestions = [];
          });

          const provinceIdByState = computed(() => {
               const stateList = useUtilitiesStore.state;
               const stateMap = Object.fromEntries(
                    stateList.map((state) => [state.province_name, state.id])
               );
               return stateMap;
          });

          const selectSuggestion = async (suggestion) => {
               if (
                    !suggestion ||
                    !suggestion.description ||
                    !suggestion.place_id
               ) {
                    console.error("Invalid suggestion:", suggestion);
                    return;
               }

               // Clear the suggestions immediately to avoid duplicates
               query.value = suggestion.description;

               useAuthStore.updateProfileFormValue("address", {
                    ...profileFormModel.value.address,
                    address_1: suggestion.description
               });

               // Use Google Maps Places Service to get more details about the selected place
               const placesService = new google.maps.places.PlacesService(
                    document.createElement("div")
               );

               placesService.getDetails(
                    { placeId: suggestion.place_id },
                    (place, status) => {
                         if (
                              status ===
                                   google.maps.places.PlacesServiceStatus.OK &&
                              place
                         ) {
                              const addressComponents =
                                   place.address_components;

                              // Extract state, city, and zip code from the place details
                              const stateComponent = addressComponents.find(
                                   (component) =>
                                        component.types.includes(
                                             "administrative_area_level_1"
                                        )
                              );
                              const cityComponent = addressComponents.find(
                                   (component) =>
                                        component.types.includes("locality")
                              );
                              const zipCodeComponent = addressComponents.find(
                                   (component) =>
                                        component.types.includes("postal_code")
                              );

                              // Update the address fields in the profile form with state, city, and zip code
                              if (stateComponent) {
                                   const stateName = stateComponent.long_name;
                                   const provinceId =
                                        provinceIdByState.value[
                                             capitalizeWords(stateName)
                                        ];

                                   if (provinceId) {
                                        useAuthStore.updateProfileFormValue(
                                             "address",
                                             {
                                                  ...profileFormModel.value
                                                       .address,
                                                  province_id: provinceId
                                             }
                                        );
                                   }
                              }

                              if (cityComponent) {
                                   const cityName = cityComponent.long_name;
                                   useAuthStore.updateProfileFormValue(
                                        "address",
                                        {
                                             ...profileFormModel.value.address,
                                             city_id: cityName
                                        }
                                   );
                              }

                              if (zipCodeComponent) {
                                   const zipCode = zipCodeComponent.long_name;
                                   useAuthStore.updateProfileFormValue(
                                        "address",
                                        {
                                             ...profileFormModel.value.address,
                                             zip_code: zipCode
                                        }
                                   );
                              }

                              // Clear suggestions AFTER updating the fields
                              setTimeout(() => {
                                   suggestions.value = [];
                              }, 500); // Adding a slight delay before clearing suggestions to ensure async operation completes
                         } else {
                              console.error(
                                   "Failed to get details from Places API:",
                                   status
                              );
                         }
                    }
               );
          };

          onMounted(async () => {
               import("vue-tel-input/vue-tel-input.css");
               document.addEventListener("click", handleOutsideClick);
               await useUtilitiesStore.getState();

               if (profileFormModel.value.address.province_id) {
                    await getCity(profileFormModel.value.address.province_id);
                    await getZipCode(
                         profileFormModel.value.address.province_id,
                         profileFormModel.value.address.city_id
                    );
               }

               query.value = profileFormModel.value.address.address_1 || ""; // Ensure it initializes with the saved address
               suggestions.value = []; // clears the suggestions
          });

          const showSuggestions = computed(() => {
               return hasInteracted.value && suggestions.value.length > 0; // Show suggestions only if interacted
          });

          return {
               eighteenYearsAgo,
               isUnder18,
               resendVerification,
               profile_form,
               profileFormModel,
               set_phone_form,
               state,
               city,
               zipCode,
               NumbersOnly,
               onCountryChanged,
               onValidatePhone,
               isValidPhoneNumber,
               handlePhoneNumberInput,
               set_phone_area,
               clearFormError,
               openDeleteConfirmation,
               closeDeleteConfirmation,
               query,
               suggestions,
               onInput,
               // onFocus,
               selectSuggestion,
               showSuggestions,
               isBdayChangable
          };
     }
};
</script>

<style scoped>
.autocomplete-suggestions {
     border: 1px solid #ccc;
     background: white;
     position: absolute;
     z-index: 1000;
     width: 100%;
     max-height: 200px;
     overflow-y: auto;
}

.suggestion-item {
     padding: 10px;
     cursor: pointer;
}

.suggestion-item:hover {
     background-color: #f0f0f0;
}
</style>

import { authStore } from "store/auth.js";
import { computed } from "vue";
import eventBus from "plugins/event.js";
import { useToaster } from "composables/use-toaster.js";

export const useKYC = () => {
     const useAuthStore = authStore();

     const { errorToast } = useToaster();

     const isUser = computed(() => {
          return useAuthStore.user;
     });

     const kycStatus = computed(() => {
          return useAuthStore.kycStatus;
     });

     const kycNotAllowed = computed(() => {
          return useAuthStore.kycNotAllowed;
     });

     const kycProvider = computed(() => {
          return useAuthStore.kycProvider;
     });

     const isEmailVerified = computed(() => {
          return useAuthStore.isEmailVerified;
     });

     const isEmptyOrNull = (data) => {
          return data == "" || data == null;
     };

     const validateKYC = (callback, navigation) => {
          let {
               notAllowed = "",
               openEmailVerification = () => {},
               openBasicKYC = () => {},
               openKYC = () => {},
               otherAction = () => {},
               defaultAction = () => {}
          } = callback;

          if (!isEmailVerified.value) {
               eventBus.emit("open:verify_email");
               return openEmailVerification();
          }

          // if existing kyc
          if (kycProvider.value == "sumsub") {
               if (!kycStatus.value) {
                    // if not id verification modal will appear (subsum)
                    eventBus.emit("open:idverification");
                    return openKYC();
               } else {
                    if (
                         isEmptyOrNull(isUser.value.address) ||
                         isEmptyOrNull(isUser.value.phone)
                    ) {
                         errorToast(
                              "Please complete your profile first",
                              "top-right"
                         );
                         eventBus.emit("open:kyc");
                         return openBasicKYC();
                    }

                    let shouldAllowed = false;

                    if (notAllowed == "gold-coin-shop") {
                         shouldAllowed = true;
                    }

                    // check if the current process if not allow to do
                    if (kycNotAllowed.value.includes(notAllowed)) {
                         if (kycStatus.value == 2) {
                              eventBus.emit("open:process_failed", {
                                   msg: "Unsuccessful ID Verfiction. Resubmission is required.",
                                   desc: "Please make sure your ID information is clearly visible, and the ID presented is not expired.",
                                   otherBtnLabel: "Try Again",
                                   otherBtnClick: () => {
                                        eventBus.emit("open:idverification");
                                   }
                              });
                              return openKYC();
                         }

                         eventBus.emit("open:idverification");
                         return openKYC();
                    } else if (navigation || shouldAllowed) {
                         // check if the current process if allow to do then proceed
                         otherAction();
                    }
               }

               // default catch
               if (!navigation) {
                    return defaultAction();
               }
          }
          if (kycProvider.value == "veriff") {
               console.log(kycStatus.value);

               if (!kycStatus.value) {
                    // if not id verification modal will appear (subsum)
                    eventBus.emit("open:idverification");
                    return openKYC();
               } else {
                    if (
                         isEmptyOrNull(isUser.value.address) ||
                         isEmptyOrNull(isUser.value.phone)
                    ) {
                         errorToast(
                              "Please complete your profile first",
                              "top-right"
                         );
                         eventBus.emit("open:kyc");
                         return openBasicKYC();
                    }

                    let shouldAllowed = false;

                    if (notAllowed == "gold-coin-shop") {
                         shouldAllowed = true;
                    }

                    // check if the current process if not allow to do
                    if (kycNotAllowed.value.includes(notAllowed)) {
                         if (kycStatus.value == 2) {
                              eventBus.emit("open:process_failed", {
                                   msg: "Unsuccessful ID Verfiction. Resubmission is required.",
                                   desc: "Please make sure your ID information is clearly visible, and the ID presented is not expired.",
                                   otherBtnLabel: "Try Again",
                                   otherBtnClick: () => {
                                        eventBus.emit("open:idverification");
                                   }
                              });
                              return openKYC();
                         }

                         eventBus.emit("open:idverification");
                         return openKYC();
                    } else if (navigation || shouldAllowed) {
                         // check if the current process if allow to do then proceed
                         otherAction();
                    }
               }

               // default catch
               if (!navigation) {
                    return defaultAction();
               }
          } else {
               if (
                    isEmptyOrNull(isUser.value.first_name) ||
                    isEmptyOrNull(isUser.value.last_name) ||
                    isEmptyOrNull(isUser.value.birthday) ||
                    isEmptyOrNull(isUser.value.address) ||
                    isEmptyOrNull(isUser.value.phone)
               ) {
                    eventBus.emit("open:kyc");
                    return openBasicKYC();
               }

               console.log("here");

               eventBus.emit("open:idverification");
               return openKYC();
          }
     };

     const validateEveryAction = (action) => {
          switch (action) {
               case "code-request":
                    const callbackCode = {
                         notAllowed: "",
                         openEmailVerification: () => {
                              return true;
                         },
                         openBasicKYC: () => {
                              return true;
                         },
                         openKYC: () => {
                              return true;
                         },
                         otherAction: () => {
                              return false;
                         },
                         defaultAction: () => {
                              return false;
                         }
                    };

                    return validateKYC(callbackCode);
               case "games-launch":
                    const callbackGameLauch = {
                         notAllowed: "games_launch",
                         openEmailVerification: () => {
                              return true;
                         },
                         openBasicKYC: () => {
                              return true;
                         },
                         openKYC: () => {
                              return true;
                         },
                         otherAction: () => {
                              return false;
                         },
                         defaultAction: () => {
                              return false;
                         }
                    };

                    return validateKYC(callbackGameLauch);
               case "add-bank":
                    const callbackAddBank = {
                         notAllowed: "add_bank",
                         openEmailVerification: () => {
                              return true;
                         },
                         openBasicKYC: () => {
                              return true;
                         },
                         openKYC: () => {
                              return true;
                         },
                         otherAction: () => {
                              return;
                         },
                         defaultAction: () => {
                              return false;
                         }
                    };

                    return validateKYC(callbackAddBank);
               case "gold-coin-shop":
                    const callbackGoldShop = {
                         notAllowed: "gold-coin-shop",
                         openEmailVerification: () => {
                              return true;
                         },
                         openBasicKYC: () => {
                              return true;
                         },
                         openKYC: () => {
                              return true;
                         },
                         otherAction: () => {
                              return;
                         },
                         defaultAction: () => {
                              return false;
                         }
                    };

                    return validateKYC(callbackGoldShop);
               case "referral":
                    const callbackReferral = {
                         notAllowed: "referral_bonus",
                         openEmailVerification: () => {
                              return {
                                   status: true,
                                   type: "Verify Email Address"
                              };
                         },
                         openBasicKYC: () => {
                              return true;
                         },
                         openKYC: () => {
                              return {
                                   status: true,
                                   type: "Set Up KYC"
                              };
                         },
                         otherAction: () => {
                              return;
                         },
                         defaultAction: () => {
                              return {
                                   status: false,
                                   type: ""
                              };
                         }
                    };

                    return validateKYC(callbackReferral);
               case "bonus":
                    const callbackBonus = {
                         notAllowed: "",
                         openEmailVerification: () => {
                              return true;
                         },
                         openBasicKYC: () => {
                              return true;
                         },
                         openKYC: () => {
                              return true;
                         },
                         otherAction: () => {
                              return;
                         },
                         defaultAction: () => {
                              return false;
                         }
                    };

                    return validateKYC(callbackBonus);
               default:
                    break;
          }
     };

     return {
          validateEveryAction
     };
};

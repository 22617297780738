<template>
     <div
          v-if="isSelfExclusion == null && coin != null"
          class="bg-white w-full h-full flex flex-col justify-start rounded-b-xl lg:rounded-bl-none rounded-r-none lg:rounded-r-xl"
     >
          <div class="flex flex-row gap-2">
               <div
                    v-if="isActionAccessible('dp_card_button')"
                    @click="tabs = 'credit'"
                    class="payment-method-tab flex flex-row items-center gap-2 hover:bg-background-3 hover:text-white font-bold font-roboto text-sm lg:text-base tracking-wide rounded-full px-6 py-2 cursor-pointer transition-colors duration-300"
                    :class="
                         tabs == 'credit'
                              ? 'bg-background-3 text-white'
                              : 'bg-white text-black'
                    "
               >
                    <svg
                         width="20"
                         height="19"
                         viewBox="0 0 20 19"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                         <path
                              d="M3.07292 2.375C1.98128 2.375 1.09375 3.28789 1.09375 4.41071V5.42857H18.9063V4.41071C18.9063 3.28789 18.0187 2.375 16.9271 2.375H3.07292ZM18.9063 8.48214H1.09375V14.5893C1.09375 15.7121 1.98128 16.625 3.07292 16.625H16.9271C18.0187 16.625 18.9063 15.7121 18.9063 14.5893V8.48214ZM4.55729 12.5536H6.53646C6.80859 12.5536 7.03125 12.7826 7.03125 13.0625C7.03125 13.3424 6.80859 13.5714 6.53646 13.5714H4.55729C4.28516 13.5714 4.0625 13.3424 4.0625 13.0625C4.0625 12.7826 4.28516 12.5536 4.55729 12.5536ZM8.02083 13.0625C8.02083 12.7826 8.24349 12.5536 8.51563 12.5536H12.474C12.7461 12.5536 12.9688 12.7826 12.9688 13.0625C12.9688 13.3424 12.7461 13.5714 12.474 13.5714H8.51563C8.24349 13.5714 8.02083 13.3424 8.02083 13.0625Z"
                              :fill="tabs == 'credit' ? '#FFF' : '#000'"
                         />
                    </svg>
                    <span class="mt-1">Credit Card</span>
               </div>
               <div
                    v-if="isActionAccessible('dp_bank_button')"
                    @click="tabs = 'wallet'"
                    class="payment-method-tab flex flex-row gap-2 items-center hover:bg-background-3 hover:text-white font-bold font-roboto text-sm lg:text-base tracking-wide rounded-full px-6 py-2 cursor-pointer transition-colors duration-300"
                    :class="
                         tabs == 'wallet'
                              ? 'bg-background-3 text-white'
                              : 'bg-white text-black'
                    "
               >
                    <svg
                         width="19"
                         height="19"
                         viewBox="0 0 19 19"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                         <g clip-path="url(#clip0_4949_27832)">
                              <path
                                   d="M9.06422 0.682465L1.27125 4.02231C0.784194 4.23105 0.512831 4.7529 0.620681 5.26779C0.72853 5.78268 1.1808 6.15842 1.70961 6.15842V6.43674C1.70961 6.89944 2.08186 7.2717 2.54457 7.2717H16.4606C16.9233 7.2717 17.2955 6.89944 17.2955 6.43674V6.15842C17.8244 6.15842 18.2801 5.78616 18.3845 5.26779C18.4888 4.74942 18.2175 4.22757 17.7339 4.02231L9.94093 0.682465C9.66261 0.564178 9.34254 0.564178 9.06422 0.682465ZM5.04945 8.38498H2.82289V15.2143C2.80202 15.2247 2.78114 15.2386 2.76027 15.2525L1.09035 16.3658C0.683303 16.6372 0.498915 17.1451 0.641555 17.6148C0.784194 18.0844 1.21907 18.4045 1.70961 18.4045H17.2955C17.7861 18.4045 18.2175 18.0844 18.3601 17.6148C18.5028 17.1451 18.3219 16.6372 17.9113 16.3658L16.2414 15.2525C16.2205 15.2386 16.1997 15.2282 16.1788 15.2143L16.1823 8.38498H13.9557V15.0647H12.5641V8.38498H10.3375V15.0647H8.66762V8.38498H6.44105V15.0647H5.04945V8.38498ZM9.50258 2.81857C9.79784 2.81857 10.081 2.93586 10.2898 3.14465C10.4986 3.35343 10.6159 3.63659 10.6159 3.93185C10.6159 4.22711 10.4986 4.51028 10.2898 4.71906C10.081 4.92784 9.79784 5.04514 9.50258 5.04514C9.20732 5.04514 8.92415 4.92784 8.71537 4.71906C8.50659 4.51028 8.3893 4.22711 8.3893 3.93185C8.3893 3.63659 8.50659 3.35343 8.71537 3.14465C8.92415 2.93586 9.20732 2.81857 9.50258 2.81857Z"
                                   :fill="tabs == 'wallet' ? '#FFF' : '#000'"
                              />
                         </g>
                         <defs>
                              <clipPath id="clip0_4949_27832">
                                   <rect
                                        width="19"
                                        height="19"
                                        :fill="
                                             tabs == 'wallet' ? '#FFF' : '#000'
                                        "
                                   />
                              </clipPath>
                         </defs>
                    </svg>
                    <span class="mt-1">Bank Transfer</span>
               </div>
          </div>
          <div
               class="flex w-full h-full"
               :class="
                    tabs == 'credit' && isActionAccessible('dp_card_button')
                         ? ''
                         : 'hidden'
               "
          >
               <CreditCard
                    :coins="coin ?? {}"
                    :setLoading="setLoading"
                    :wp_response="wp_response"
                    :payframeClientLoaded="payframeClientLoaded"
                    :confirmationFunc="confirmationFunc"
                    :loading="loading"
                    :initWorldPay="initWorldPay"
                    :setConfirmationFunc="setConfirmationFunc"
               />
          </div>
          <div
               class="flex w-full h-full"
               :class="
                    tabs == 'wallet' && isActionAccessible('dp_bank_button')
                         ? ''
                         : 'hidden'
               "
          >
               <Wallet
                    :tabs="tabs"
                    :coins="coin ?? {}"
                    :setLoading="setLoading"
               />
          </div>
     </div>
     <SelfExclusionAlert
          :lightMode="true"
          v-else-if="isSelfExclusion != null"
     />
     <div
          v-else
          class="bg-white h-full w-full flex flex-col justify-center items-center rounded-b-xl lg:rounded-bl-none rounded-r-none lg:rounded-r-xl"
     >
          <svg
               aria-hidden="true"
               class="w-8 h-8 text-primal-black animate-spin fill-primal-yellow"
               viewBox="0 0 100 101"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
          >
               <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
               ></path>
               <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
               ></path>
          </svg>
     </div>
     <paymentSuccess :successCoins="paymentSuccessCoins" />
     <paymentFailed />
     <loading-overlay :isLoading="loading" />
</template>
<script>
import { onMounted, reactive, ref, computed, watch } from "vue";
import { useFormatter } from "composables/use-formatter.js";
import { useRouter, useRoute } from "vue-router";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import paymentSuccess from "components/modals/paymentSuccess.vue";
import paymentFailed from "components/modals/paymentFailed.vue";
import CreditCard from "./component/credit.vue";
import Wallet from "./component/wallet.vue";
import { utilitiesStore } from "store/utilities.js";
import { cardStore } from "store/cards.js";
import SelfExclusionAlert from "components/selfExclusionAlert/index.vue";
import { useBank } from "composables/use-bank.js";
import { authStore } from "store/auth.js";
import _ from "underscore";
import { useToaster } from "composables/use-toaster.js";
import { eprotectUrl } from "config/services.js";
import { coinsStore } from "store/coins";
import { configureWorldPay } from "config/services.js";

export default {
     components: {
          LoadingOverlay,
          paymentSuccess,
          paymentFailed,
          CreditCard,
          Wallet,
          SelfExclusionAlert
     },
     setup() {
          const { isActionAccessible } = useBank();
          const router = useRouter();
          const route = useRoute();
          const { money } = useFormatter();
          const { errorToast } = useToaster();

          const useUtilitiesStore = utilitiesStore();
          const useCardStore = cardStore();
          const useAuthStore = authStore();
          const useCoinsStore = coinsStore();

          const loading = ref(false);

          const wp_response = ref(null);
          const payframeClientLoaded = ref(null);
          const confirmationFunc = ref(false);

          const tabs = ref(
               route.query.panel
                    ? "credit"
                    : isActionAccessible("dp_card_button")
                      ? "credit"
                      : "wallet"
          );

          const isSelfExclusion = computed(() => {
               return useAuthStore.isSelfExclusion;
          });

          const bankStatus = computed(() => {
               if (useCardStore.bankStatus) {
                    setActiveTab(useCardStore.bankStatus);
               }
               return useCardStore.bankStatus;
          });

          const coin = computed(() => {
               return useUtilitiesStore.selected_coin;
          });

          const worldpayStatus = computed(() => {
               if ([true, false].includes(useCoinsStore.worldpayStatus)) {
                    const status =
                         useCoinsStore.worldpayStatus == true ? "prod" : "dev";
                    initCardForm(status);
                    return status;
               } else null;
          });

          const paymentSuccessCoins = reactive({
               gold_coins: 0,
               silver_coins: 0
          });

          const goTo = () => {
               router.push({ path: "/gold-coin-shop" });
          };

          const setLoading = (data) => {
               loading.value = data;
          };

          const fetchGetCoins = async () => {
               await useUtilitiesStore.getCoins({
                    id: route.path.split("/")[2]
               });
          };

          async function setPaymentSuccessCoins(res) {
               paymentSuccessCoins.gold_coins = money(res.gold_coins);
               paymentSuccessCoins.silver_coins = money(res.silver_coins);
          }

          const getPercentage = (discount, amount) => {
               return money(amount - (discount / 100) * amount);
          };

          const setActiveTab = (newVal) => {
               const filter = _.filter(newVal, function (params) {
                    return ["dp_bank_button", "dp_card_button"].includes(
                         params["slug"]
                    );
               });

               if (filter?.length != 0) {
                    const bank = _.filter(newVal, function (params) {
                         return params["slug"] == "dp_bank_button";
                    });

                    const card = _.filter(newVal, function (params) {
                         return params["slug"] == "dp_card_button";
                    });

                    if (card[0].note == 1) {
                         tabs.value = "credit";
                         return;
                    } else if (bank[0].note == 1) {
                         tabs.value = "wallet";
                         return;
                    }
                    return;
               }

               tabs.value = "credit";
          };

          const inputsEmptyCallback = (res) => {
               var isEmpty = res.allInputsEmpty;
               if (isEmpty) {
                    errorToast("Card input fields empty", "top-right");
                    // errorCard.value = "Card input fields empty";
                    return true;
               } else {
                    return false;
               }
          };

          const payframeClientCallback = async (response) => {
               if (response.timeout) {
                    var elapsedTime = new Date().getTime() - startTime.value;
                    // document.getElementById("timeoutMessage").value =
                    //   "Timed out after " + elapsedTime + "ms";
                    errorToast(
                         "Timed out after " + elapsedTime + "ms",
                         "top-right"
                    );
                    setLoading(false);
               } else {
                    if (response.response === "870") {
                         if (response) {
                              wp_response.value = response;
                              setConfirmationFunc(true);
                              setLoading(false);
                         }
                    } else if (
                         response.response === "871" ||
                         response.response === "872" ||
                         response.response === "873" ||
                         response.response === "874" ||
                         response.response === "876"
                    ) {
                         errorToast(
                              response?.message
                                   ? response.message
                                   : "Please check and re-enter your credit card number and try again.",
                              "top-right"
                         );
                         setLoading(false);
                    } else if (
                         response.response === "881" ||
                         response.response === "882" ||
                         response.response === 883
                    ) {
                         errorToast(
                              response?.message
                                   ? response.message
                                   : "Please check and re-enter your credit card number and try again.",
                              "top-right"
                         );
                         setLoading(false);
                    } else if (response.response === "884") {
                         document.getElementById("submitButton").disabled =
                              true;
                         setLoading(false);
                    } else {
                         errorToast(
                              "We are experiencing technical difficulties. Please try again or contact us to complete your order",
                              "top-right"
                         );
                         setLoading(false);
                    }
               }
          };

          const initWorldPay = async () => {
               var payframeClient = await new EprotectIframeClient(
                    configureWorldPay(
                         eprotectUrl[worldpayStatus.value],
                         inputsEmptyCallback,
                         payframeClientCallback
                    )
               );
               payframeClientLoaded.value = payframeClient;
          };

          const setConfirmationFunc = (data) => {
               confirmationFunc.value = data;
          };

          onMounted(async () => {
               window.scrollTo({
                    top: 0,
                    behavior: "smooth"
               });

               await useCardStore.getBankStatus();
               await useCoinsStore.getWorldPay();
               await fetchGetCoins();
               await useUtilitiesStore.getState();
          });

          const initCardForm = (value) => {
               loading.value = true;

               const isExist1 = document.querySelector(
                    "#eprotect-jquery-import"
               );

               const isExist2 = document.querySelector("#eprotect-import");

               if (isExist1 == null && isExist2 == null) {
                    var script1 = document.createElement("script");
                    script1.id = "eprotect-jquery-import";
                    script1.src = eprotectUrl[value]["eprotect-jquery-import"];
                    document.body.appendChild(script1);

                    var script2 = document.createElement("script");
                    script2.id = "eprotect-import";
                    // https://request.eprotect.vantivprelive.com/eProtect/js/eProtect-iframe-client4.min.js
                    script2.src = eprotectUrl[value]["eprotect-import"];
                    document.body.appendChild(script2);
               }

               let interval = setInterval(() => {
                    if (typeof EprotectIframeClient == "function") {
                         initWorldPay();
                         loading.value = false;
                         clearInterval(interval);
                    }
               }, 1000);
          };

          watch(bankStatus, (newVal) => {
               if (newVal) {
                    setActiveTab(newVal);
               }
          });

          watch(worldpayStatus, async (newVal) => {
               if (newVal) {
                    initCardForm(newVal);
               }
          });

          return {
               goTo,
               coin,
               money,
               tabs,
               loading,
               setLoading,
               paymentSuccessCoins,
               setPaymentSuccessCoins,
               getPercentage,
               isActionAccessible,
               isSelfExclusion,
               confirmationFunc,
               wp_response,
               payframeClientLoaded,
               initWorldPay,
               setConfirmationFunc
          };
     }
};
</script>
